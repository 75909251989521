const countriesAllowingLeadingZero = ['it', 'ie']

export default class PhoneFormatter {
  /**
     * Formats a phone number to international format:
     *  {prefix}{phoneNumber} Ex: +32666777888
     *  Removes extra 0 in the start of the number
     *  Removes 00 formatted prefix (Ex: 0032)
     *  Adds prefix at the beginning of phone number (Ex: +32)
     * @param {String}          prefix          -- Expected format '+32'
     * @param {String}          phoneNumber     -- Any format
     * @param {String}          [iso]           -- Country code in ISO format (optional)
     *
     * @returns {String}
     */
  static toInternationalFormat (prefix, phoneNumber, iso) {
    if (!phoneNumber) {
      return ''
    }

    prefix = prefix || ''
    phoneNumber = this.clearPrefixFromPhoneNumber(prefix, phoneNumber, iso)

    // Concats prefix and clean phone number
    return `${prefix}${phoneNumber}`
  }

  static clearPrefixFromPhoneNumber (prefix, phoneNumber, iso) {
    const zeroZeroPrefix = `00${prefix.substring(1, prefix.length)}`

    // Remove first prefix (in 00 format) instance, if any
    if (phoneNumber.startsWith(zeroZeroPrefix)) {
      phoneNumber = phoneNumber.replace(zeroZeroPrefix, '')
    }

    // Remove first prefix instance, if any
    if (phoneNumber.startsWith(prefix)) {
      phoneNumber = phoneNumber.replace(prefix, '')
    }

    // Remove leading '0', if any and it's not allowed
    if (this.shouldRemoveLeadingZero(iso) && phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.replace('0', '')
    }

    return phoneNumber
  }

  static shouldRemoveLeadingZero (iso) {
    const allowLeadingZero = iso && countriesAllowingLeadingZero.indexOf(iso) !== -1
    return !allowLeadingZero
  }
}
