import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import domEventsHelper from '../../../js/document/dom-events-helper'
import registeredEvents from '../../../js/helpers/registered-events'
import * as cssHelper from '../../../js/document/css'
import * as scrollHelper from '../../../js/document/scroll'
import { profileDataEvents, navigationEvents } from '../../../js/document/event-types'

const navApi = 'c-nav'

const classNames = {
  hasChild: 'has-child',
  navList: `${navApi}__list`,
  navListReversed: `${navApi}__list--reverse`,
  navItem: `${navApi}__item`,
  navItemBack: `${navApi}__item--back`,
  navLink: `${navApi}__link`,
  navLinkText: `${navApi}__link-text`,
  opened: 'is-opened'
}
const attributes = {
  level: `data-${navApi}__level`,
  itemDefinition: `data-${navApi}-item__definition`,
  track: 'data-track'
}

const definition = {
  name: navApi,
  props: [
    {
      name: 'variant',
      type: 'string',
      attr: 'data-c-nav__variant',
      allowedValues: [
        'default',
        'list',
        'block',
        'dropdown',
        'inline',
        'collapsible'
      ],
      defaultValue: 'default'
    },
    {
      name: 'shownLevel',
      type: 'number',
      attr: 'data-c-nav__shown-level',
      defaultValue: 1
    }
  ]
}

export default class Nav extends Component {
  /**
   * Creates a new nav behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */

  constructor (element) {
    super(element, definition.name)
    this.rootNavList = this.element.children[0]
    this.variant = this.getProp('variant')

    window.addEventListener(profileDataEvents.NAME_CHANGED, (e) => this.changeNavLinkText(e))

    element[definition.name] = {
      ...element[definition.name],
      destroy: this.destroy.bind(this)
    }

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attributes.track) && { track: this.element.attributes[attributes.track].value }
    })

    this._navEvents = [
      [this.element, { click: (ev) => this.clicked(ev) }]
    ]
    domEventsHelper.attachEvents(this._navEvents, definition.name)

    if (this.variant.toLowerCase() === 'dropdown') {
      document.body.addEventListener('click', () => this.resetNav())
    }

    this.initialLevel = this.getLevelShown()

    if (parseInt(this.initialLevel) > 1) {
      const initialNavItemOpened = this.element.querySelector(`.${classNames.navItem}.${classNames.opened}`) || null
      const initialNavList = initialNavItemOpened.querySelector(`.${classNames.navList}[${attributes.level}="${this.initialLevel}"]`)

      if (initialNavItemOpened) {
        this.equalizeHeights(initialNavList)
      }
    }
  }

  destroy () {
    domEventsHelper.detachEvents(this._navEvents, definition.name)
    document.body.removeEventListener('click', () => this.resetNav())
    this.element[navApi] = null
    delete this.element[navApi]
  }

  clicked (ev) {
    // To solve a Safari bug with the pointer events, we have to be sure that the target is the link
    const target = ev.target.classList.contains(classNames.navLink) ? ev.target : ev.target.parentNode

    // Discard other events except links
    if (!target.classList.contains(classNames.navLink)) return

    // Store navItem reference (let's assume it's the parent)
    const navItem = target.parentNode

    // Discard if navItem is not a navItem
    if (!navItem.classList.contains(classNames.navItem)) return

    const eventData = this.getClickEventData(navItem, target)
    this.events.emit(navigationEvents.CLICK_NAVIGATION, eventData)

    // Capture event if is a back navItem
    if (navItem.classList.contains(classNames.navItemBack)) {
      ev.stopPropagation()
      return this.clickedBackNavItem(navItem)
    }
    // Capture event if navItem hasChild
    if (navItem.classList.contains(classNames.hasChild)) {
      ev.stopPropagation()
      return this.clickedNavItemWithChild(navItem)
    }
  }

  clickedBackNavItem (navItem) {
    // Store current and parent navList references or break
    const currentNavList = navItem.closest('.' + classNames.navList)
    const parentNavList = currentNavList.parentNode.closest('.' + classNames.navList)
    if (!currentNavList || !parentNavList) return
    // Decrement the shown level by 1
    const currentLevel = parseInt(this.getProp('shownLevel'))
    this.setLevelShown(currentLevel - 1)
    // Finish some cosmetic work
    if (this.variant === 'block') {
      this.equalizeHeights(parentNavList, this.closeNavListItems(parentNavList))
      this.scrollParentToTop()
    } else {
      this.closeNavListItems(parentNavList)
    }
  }

  clickedNavItemWithChild (navItem) {
    // If current item was opened, close it and break
    if (navItem.classList.contains(classNames.opened)) return this.closeNavItem(navItem)
    // Store current and next navList references or break
    const currentNavList = navItem.closest('.' + classNames.navList)
    const nextNavList = navItem.querySelector('.' + classNames.navList)
    if (!currentNavList || !nextNavList) return
    // Close current navList items
    this.closeNavListItems(currentNavList)
    // Open the current item
    this.openNavItem(navItem)
    // Finish some cosmetic work
    if (this.variant === 'block') {
      this.equalizeHeights(nextNavList)
      this.scrollParentToTop()
    }
  }

  openNavItem (navItem) {
    // Search for next navList or break
    const nextNavList = navItem.querySelectorAll('.' + classNames.navList)[0]
    if (!nextNavList) return
    // Add the opened className
    navItem.classList.add(classNames.opened)
    // Set shown level
    this.setProp('shownLevel', parseInt(nextNavList.getAttribute(attributes.level)))
    // this.element.setAttribute(attributes.shownLevel, parseInt(nextNavList.getAttribute(attributes.level)))
    // Finish some cosmetic work
    if (this.variant.toLowerCase() === 'dropdown') this.fixHorizontalOverflow(nextNavList)
  }

  closeNavItem (navItem) {
    navItem.classList.remove(classNames.opened)
  }

  closeNavListItems (navList) {
    const openedNavItems = [...navList.querySelectorAll('.' + classNames.opened)]
    openedNavItems.forEach(openedNavItem => { this.closeNavItem(openedNavItem) })
  }

  getLevelShown () {
    return this.getProp('shownLevel') || 1
  }

  setLevelShown (newLevel) {
    if (newLevel <= 0) return
    this.setProp('shownLevel', newLevel)
  }

  resetNav () {
    const openedNavItems = [...this.element.querySelectorAll('.' + classNames.opened)]
    openedNavItems.forEach(el => { el.classList.remove(classNames.opened) })

    const navListItems = [...this.element.querySelectorAll('.' + classNames.navList)]
    navListItems.forEach(el => { el.classList.remove(classNames.navListReversed) })
  }

  getClickEventData (navItem, target) {
    const isBackNavigation = navItem.classList.contains(classNames.navItemBack)
    const hasChild = navItem.classList.contains(classNames.hasChild)
    const isLink = target.getAttribute('href') !== null

    let currentNavItem = navItem
    const navItemsInPath = []
    let isNavItem = true
    while (isNavItem) {
      if (currentNavItem.classList.contains(classNames.navItem)) {
        const linkText = currentNavItem.querySelector(`.${classNames.navLink} .${classNames.navLinkText}`).innerText
        navItemsInPath.unshift(linkText.toLowerCase())
      } else if (!currentNavItem.classList.contains(classNames.navList)) {
        isNavItem = false
      }

      currentNavItem = currentNavItem.parentNode
    }

    const itemDefinition = navItem.getAttribute(attributes.itemDefinition)
    return {
      hasChild,
      isBackNavigation,
      isLink,
      path: navItemsInPath,
      itemDefinition
    }
  }

  equalizeHeights (currentItem, done) {
    const currentItemHeight = cssHelper.getStyle(currentItem, 'height')
    const componentHeight = cssHelper.getStyle(this.element, 'height')
    const transitionTime = cssHelper.cssTimeToMs(cssHelper.getStyle(this.rootNavList, 'transition-duration'))

    setTimeout(equalize(this.element), (componentHeight <= currentItemHeight) ? 0 : transitionTime)
    setTimeout(callDone, transitionTime)

    function equalize (el) {
      el.style.height = currentItemHeight
    }

    function callDone () {
      if (done && typeof done === 'function') done()
    }
  }

  scrollParentToTop () {
    if (this.element.parentNode.scrollTop !== 0) scrollHelper.smooth(this.element.parentNode, 0, 0)
  }

  fixHorizontalOverflow (navList) {
    navList.classList.remove(classNames.navListReversed)
    const rect = navList.getBoundingClientRect()
    const horizontalOverflow = (rect.right < 0 || (rect.right > window.innerWidth))
    if (horizontalOverflow) navList.classList.add(classNames.navListReversed)
  }

  changeNavLinkText (event) {
    const attribute = event.detail?.attribute
    const text = event.detail?.text
    if (!attribute || !text) return

    const navLinks = this.element.querySelectorAll(`[${attribute}]`)

    navLinks.forEach(navLink => {
      if (navLink && navLink.firstElementChild) {
        navLink.firstElementChild.innerText = text
      }
    })
  }
}

registerComponent(Nav, definition.name, definition)
