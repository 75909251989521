import { defaultModalData } from './c-modal.template'

export const ModalFooterTemplate = (d) => {
  d = { ...defaultModalData, ...d }
  return `
<div class="c-modal__footer"> 
    ${d.footerContent}
 </div>
`
}
