/**
 * The NumberScoreData contains all the data needed to hydrate a Number score component
 *
 * @typedef {Object}      NumberScoreData
 *
 * @property {String}     variant           - The variant ot the number score
 * @property {String}     header__size      - The variant ot the title number score
 * @property {String}     scoreTitle        - The score title to be displayed next to the number
 * @property {String}     scoreLabel        - The score label to be displayed next to the number
 * @property {String}     scoreGrade        - The score grade of the number score
 * @property {String}     scoreBasis        - The score basis of the number score
 * @property {String}     arrowDirection    - Points the arrow to the right
 * @property {Boolean}    smallBasis        - Reduces the basis font size
 * @property {String}     extraClasses      - The extra classes for the component
 */

import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-number-score__tokens.json')['c-number-score']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/number-score/data/c-number-score__tokens.json`)['c-number-score'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultNumberScoreData = {
  variant: 'default',
  header__size: 'default',
  extraClasses: '',
  scoreTitle: '',
  scoreLabel: '',
  scoreBasis: '',
  smallBasis: false,
  arrowDirection: 'left',
  labelPosition: 'right'
}

export const NumberScoreTemplate = (d) => {
  d = { ...defaultNumberScoreData, ...d }
  return `
  <div class="c-number-score
      ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''}
      c-number-score--arrow-${d.variant ? d.variant : 'left'}
      c-number-score--label-${d.labelPosition ? d.labelPosition : 'left'}
      ${d.smallBasis ? 'c-number-score--small-basis' : ''}
      ${d.extraClasses || ''}"
      data-js-component="c-number-score">
      ${d.labelPosition === 'left'
        ? `<div class="c-number-score__title-label c-number-score__title-label-left">
          ${d.scoreTitle
            ? `<span class="c-number-score__title m-heading ${getTokenClass('header__size', d.header__size, tokens)}" data-c-number-score__title> ${d.scoreTitle} </span>`
            : ''}
          ${d.scoreLabel
            ? `<span class="c-number-score__label" data-c-number-score__label> ${d.scoreLabel} </span>`
            : ''}          
          </div>`
        : ''}
      
      <div class="c-number-score__body">
          <span class="c-number-score__grade" data-c-number-score__grade>${d.scoreGrade > 0 ? d.scoreGrade : '-'}</span>
          ${d.scoreBasis
            ? `<span class="c-number-score__basis">${d.scoreBasis}</span>`
            : ''}
      </div>
      
      ${d.labelPosition === 'right'
        ? `
          <div class="c-number-score__title-label c-number-score__title-label-right">
            ${d.scoreTitle
              ? `<span class="c-number-score__title m-heading ${getTokenClass('header__size', d.header__size, tokens)} " data-c-number-score__title> ${d.scoreTitle} </span>`
              : ''}
            ${d.scoreLabel
              ? `<span class="c-number-score__label" data-c-number-score__label> ${d.scoreLabel} </span>`
              : ''}          
          </div>`
        : ''}
  </div>`
}
