import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { PhoneInputMessagesTemplate } from './c-phone-input__messages.template'
import PhoneFormatter from './phone-formatter'

require('../textbox/main')

const classNames = {
  opened: 'is-open',
  active: 'is-active',
  hideCountrySelector: 'c-phone-input__country-selector--hidden'
}

const attributes = {
  elementIso: 'country',
  elementDial: 'dial',
  elementPattern: 'pattern',
  countryItemIso: 'c-phone-input__country-id',
  countryItemDial: 'c-phone-input__country-dial',
  countryItemPattern: 'c-phone-input__country-pattern'
}

const componentQueries = {
  numberTextbox: '[data-js-element="c-phone-input__number"]',
  countrySelector: '[data-js-element="c-phone-input__country-selector"]',
  countriesFloatingBox: '[data-js-component="c-floating-box"]',
  countryItem: `[data-${attributes.countryItemIso}]`,
  countryFlag: '[data-m-country-flag__country-id]'
}

const definition = {
  name: 'c-phone-input',
  props: [
    {
      name: 'prefix',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'number',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'iso',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'value',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'state',
      type: 'string',
      attr: '.has-',
      allowedValues: [
        '',
        'success',
        'warning',
        'error'
      ],
      defaultValue: ''
    }
  ]
}

export default class PhoneInput extends Component {
  /**
   * Creates a new textbox behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.silentErrors = true
    if (!this.numberTextboxApi) this._init()

    this.messagesElement = this.element.querySelector('.c-phone-input__messages')
    this.messagePattern = this.element.getAttribute('data-message-pattern')
    this.messageRequired = this.element.getAttribute('data-message-required')

    this.element[this.name].validate = this.validate.bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)
  }

  getValue () {
    if (!this.numberTextboxApi) this._init()

    let currentNumber = this.getProp('number')
    if (typeof currentNumber === 'undefined') {
      currentNumber = this.numberTextboxApi.getProp('value') || ''
      this.setProp('number', currentNumber)
    }

    if (currentNumber !== '') {
      // Do not use this.setProp('value', value) because prop is not initialized yet
      // and method will throw an error. Call inner method instead
      this._tryGetCountryAndUpdateSubProperties(currentNumber)
    }

    const currentPrefix = this.getProp('prefix')
    if (typeof currentPrefix === 'undefined') {
      const initialPrefix = this.element.getAttribute(`data-${attributes.elementDial}`)
      this.setProp('prefix', initialPrefix)
    }

    const currentIso = this.getProp('iso')
    if (typeof currentIso === 'undefined') {
      this.setProp('iso', '')
    }

    return PhoneFormatter.toInternationalFormat(this.getProp('prefix'), this.getProp('number'), this.getProp('iso'))
  }

  async setValue (value) {
    this._tryGetCountryAndUpdateSubProperties(value)
  }

  async setNumber (number) {
    await this.numberTextboxApi.setProp('value', number)
    this.element.classList[number ? 'add' : 'remove']('has-value')
    this._updateValueProperty()
  }

  async setPrefix (prefix) {
    this._updateValueProperty()

    // Remove active class for all the countries and add it to the selected one
    // Update the selected country flag by updating the iso
    // Set the attributes of the element
    this.countryItems.forEach(countryItem => {
      if (countryItem.getAttribute(`data-${attributes.countryItemDial}`) === prefix) {
        countryItem.classList.add(classNames.active)

        const selectedCountryIso = countryItem.getAttribute(`data-${attributes.countryItemIso}`)
        const selectedCountryPattern = countryItem.getAttribute(`data-${attributes.countryItemPattern}`)

        this.setProp('iso', selectedCountryIso)
        this.countrySelector.setAttribute(`data-${attributes.countryItemIso}`, selectedCountryIso)
        this.element.setAttribute(`data-${attributes.elementIso}`, selectedCountryIso)
        this.countrySelectorFlag.setAttribute('data-m-country-flag__country-id', selectedCountryIso)

        this.element.setAttribute(`data-${attributes.elementDial}`, prefix)
        this.element.setAttribute(`data-${attributes.elementPattern}`, selectedCountryPattern)
      } else {
        countryItem.classList.remove(classNames.active)
      }
    })
    if (this.silentErrors !== undefined) {
      this.validate()
    }
  }

  _init () {
    // Init number textbox
    this.numberTextbox = this.element.querySelector(componentQueries.numberTextbox)
    this.numberTextboxApi = this.numberTextbox['c-textbox']

    // Get country selector
    this.countrySelector = this.element.querySelector(componentQueries.countrySelector)

    // Get country flag selector
    this.countrySelectorFlag = this.countrySelector.querySelector(componentQueries.countryFlag)

    // Get floating box
    this.countriesFloatingBox = this.element.querySelector(componentQueries.countriesFloatingBox)
    this.countriesFloatingBoxApi = this.countriesFloatingBox['c-floating-box']

    // Get countries close button inside floating box
    this.countriesCloseBtn = this.countriesFloatingBox.querySelector(componentQueries.countriesCloseButton)

    // Get country items
    this.countryItems = [...this.countriesFloatingBox.querySelectorAll(componentQueries.countryItem)]

    // Subscribe to country selector events and open the floating box
    this.element.addEventListener('click', (ev) => {
      if (ev.target.className === this.countrySelector.className) {
        const isCountriesFloatingBoxOpen = this.countriesFloatingBoxApi.getProp('opened')
        this.countriesFloatingBoxApi.setProp('opened', !isCountriesFloatingBoxOpen)
        this.element.classList[isCountriesFloatingBoxOpen ? 'remove' : 'add'](classNames.opened)
      } else {
        this.countriesFloatingBoxApi.setProp('opened', false)
        this.element.classList.remove(classNames.opened)
      }
    })

    // Close the floating box when it's clicking outside
    document.addEventListener('click', (ev) => {
      if (ev.target.className !== this.countrySelector.className) {
        this.countriesFloatingBoxApi.setProp('opened', false)
        this.element.classList.remove(classNames.opened)
      }
    })

    if (this.countryItems.length > 0) {
      // Subscribe to country change
      this.countryItems.forEach(countryItem => {
        countryItem.addEventListener('click', ev => {
          const selectedCountryDial = countryItem.getAttribute(`data-${attributes.countryItemDial}`)

          this.setProp('prefix', selectedCountryDial)
        })
      })
    } else {
      this.element.classList.add(classNames.hideCountrySelector)
    }

    // Subscribe to prop changed
    this.numberTextboxApi.events.on('propChanged', async (changes) => {
      if (changes.name === 'value') {
        await this.setProp('number', changes.value)
        this.validate()
      }
    })
  }

  enableErrors () {
    this.silentErrors = false
  }

  validate (validateOnly = false) {
    const value = this.getValue()
    const number = this.getProp('number')
    const pattern = this.element.getAttribute(`data-${attributes.elementPattern}`)
    const regex = (pattern) ? new RegExp(pattern) : null
    const patternIsValid = regex ? regex.test(value) : true
    const requiredIsValid = this.element.hasAttribute('required') ? number.length > 0 : true
    const isEmpty = number.length === 0

    // This is valid if its empty and not Required OR if it has value and the value is valid
    const isValid = (isEmpty && requiredIsValid) || (!isEmpty && requiredIsValid && patternIsValid)
    let messages = []
    let messageTypes = []
    if ((!validateOnly && !this.silentErrors) || this.isErrorState) {
      if (!isValid) {
        messages = this._getValidationMessages(patternIsValid, requiredIsValid)
        messageTypes = this._getValidationMessageTypes(patternIsValid, requiredIsValid)
      }
      this._styleValidity(isValid, messages)
    }
    return {
      isValid,
      errorMessages: messages,
      errorTypes: messageTypes,
      fieldName: this.numberTextbox.querySelector('input').name
    }
  }

  _getValidationMessages (patternIsValid, requiredIsValid) {
    const messages = []
    if (!requiredIsValid && this.messageRequired) {
      messages.push(this.messageRequired)
    } else if (!patternIsValid && this.messagePattern) {
      messages.push(this.messagePattern)
    }
    return messages
  }

  _getValidationMessageTypes (patternIsValid, requiredIsValid) {
    const messageTypes = []
    if (!requiredIsValid && this.messageRequired) {
      messageTypes.push('ValueMissing')
    } else if (!patternIsValid && this.messagePattern) {
      messageTypes.push('PatternMismatch')
    }
    return messageTypes
  }

  _styleValidity (isValid, messages) {
    if (!isValid) {
      this.element.classList.add('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = PhoneInputMessagesTemplate(messages)
      }
    } else {
      this.element.classList.remove('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ''
      }
    }
  }

  _tryGetCountryAndUpdateSubProperties (value) {
    const countryItem = this.countryItems
      .find(item => {
        const prefix = item.getAttribute(`data-${attributes.countryItemDial}`)
        const zeroZeroPrefix = `00${prefix.substring(1, prefix.length)}`
        return value.startsWith(prefix) || value.startsWith(zeroZeroPrefix)
      })

    if (countryItem !== undefined) {
      const prefix = countryItem.getAttribute(`data-${attributes.countryItemDial}`)
      const iso = countryItem.getAttribute(`data-${attributes.countryItemIso}`)
      const number = PhoneFormatter.clearPrefixFromPhoneNumber(prefix, value, iso)

      if (this.getProp('prefix') !== prefix) this.setProp('prefix', prefix)
      if (this.getProp('iso') !== iso) this.setProp('iso', iso)
      if (this.getProp('number') !== number) this.setProp('number', number)
    }
  }

  _updateValueProperty () {
    // Do not use this.setProp('value', value) if is not initialized yet
    // as method will throw an error.
    // This happens when "getValue", called in base constructor to initialize property, hasn't finished yet.
    if (this.props.value !== undefined) {
      const prefix = this.getProp('prefix')
      const number = this.getProp('number')
      if ((number || number === '') && prefix) {
        const formatted = PhoneFormatter.toInternationalFormat(prefix, number, this.getProp('iso'))
        this.setProp('value', formatted, { forceUpdate: true })
      }
    }
  }
}

registerComponent(PhoneInput, definition.name, definition)
