/**
 * The TitleData contains all the data needed to hydrate a Title component
 *
 * @typedef {Object}      TitleData
 *
 * @property {String}     tag               - The tag to be used as a HTML tag
 * @property {String}     size              - The size of the text (tiny | small | medium | large | huge)
 * @property {String}     extraClasses      - Extra classes if needed
 * @property {String}     text              - The text to be shown as title
 */

import { getTokenClass } from '../../../js/helpers/tokens'
import { encodeAttributes } from '../../../js/helpers/string'

// Import tokens
const tokensShared = require('./data/c-title__tokens.json')['c-title']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/title/data/c-title__tokens.json`)['c-title'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultTitleData = {
  text: '',
  tag: 'h2',
  size: 'tiny',
  extraClasses: ''
}

export const TitleTemplate = (d) => {
  d = { ...defaultTitleData, ...d }
  return `
  <${d.tag}
  class="c-title m-heading
  ${d.size ? getTokenClass('size', d.size, tokens) : ''}
  ${d.extraClasses ? `${d.extraClasses}` : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.text}
  </${d.tag}>
`
}
