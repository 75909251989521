import { getTokenClass } from '../../../js/helpers/tokens'
import { defaultModalData } from './c-modal.template'
import { BtnTemplate } from '../btn/c-btn.template'

// Import tokens
const tokensShared = require('./data/c-modal__tokens.json')['c-modal']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal/data/c-modal__tokens.json`)['c-modal'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const ModalHeaderTemplate = (d) => {
  d = { ...defaultModalData, ...d }

  if ((d.id && d.closable) || (d.title && d.title !== '')) {
    return `
      <div class="c-modal__header ${d.async ? 'c-modal__header--async' : ''}
          ${getTokenClass('header__cs', d.style, tokens)} ${d.async ? 'c-modal--async' : ''} ${d.extraClasses}">
          <${d.headerTag}
            class="c-modal__header-text m-heading ${getTokenClass('header__size', d.headerSize, tokens)}">
              ${d.title}
          </${d.headerTag}>
          ${d.id && d.closable
            ? BtnTemplate({
              type: 'button',
              icon: 'cross',
              iconPosition: 'left',
              variant: 'icon-only',
              extraClasses: 'c-modal__close',
              attributes: { 'data-c-modal__action': 'close', 'data-c-modal__id': d.id }
            })
            : ''}
       </div>
      `
  }

  return ''
}
