import { defaultModalData } from './c-modal-v2.template'

export const ModalV2FooterTemplate = (d) => {
  d = { ...defaultModalData, ...d }
  return `
  <div class="c-modal-v2__footer" data-c-modal-v2-footer> 
      ${d.footerContent}
  </div>
  `
}
