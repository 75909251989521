import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { debounce } from '../../../js/utils'
const NoUiSlider = require('nouislider')

const classNames = {
  attributeSelector: 'data-c-range__',
  slider: 'c-range__slider',
  currentMinValue: 'c-range__current-min',
  currentMaxValue: 'c-range__current-max'
}

/**
 * The RangeOptionData contains all data related with range configuration
 *
 * @typedef {Object}          RangeOptionData
 *
 * @property {String}         text              - The min selectable value on a range
 * @property {String}         value             - The max selectable value on a range
 * @property {Boolean}        [selected]        - The range minimum step will prevent select values in between
 */

const definition = {

  name: 'c-range',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */
  props: [{
    name: 'disable',
    type: 'boolean',
    attr: '.is-disabled',
    defaultValue: false
  }, {
    name: 'options',
    type: 'collection|object'
  }, {
    name: 'currentValues',
    attr: `${classNames.attributeSelector}current-values`,
    type: 'array'
  }, {
    name: 'rangeMin',
    type: 'number',
    attr: `${classNames.attributeSelector}range-min`,
    defaultValue: 0
  }, {
    name: 'rangeMax',
    type: 'number',
    attr: `${classNames.attributeSelector}range-max`,
    defaultValue: 500
  }, {
    name: 'margin',
    type: 'number',
    attr: `${classNames.attributeSelector}margin`,
    defaultValue: 50
  }, {
    name: 'step',
    type: 'number',
    attr: `${classNames.attributeSelector}step`,
    defaultValue: 50
  }]
}
export default class Range extends Component {
  /**
   * Creates a new Range behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this._init()

    this.element[definition.name].remove = (this.remove).bind(this)
  }

  /**
   * Checks that current values are not the same
   * @param {Array} values
   * @returns {Boolean} If true, values can be set
   */
  checkCurrentValues (values) {
    this.lastCurrentValues = this.props.currentValues
    const property = this._getPropInstance('currentValues')
    if (!property.isAllowedValue.bind(property)(values)) { return false }
    return property.isAllowedValue.bind(property)(values)
  }

  /**
   * Set current min and max values
   * @param {Array} values
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setCurrentValues (values, options = {}) {
    // Setting array to numbers to be consistent with data comparison
    values = (this.props.currentValues = values.map((value) => parseInt(value)))
    // Refreshing the labels in case current values do not change but options labels do
    this._updateLabels(values)

    if (JSON.stringify(values) !== JSON.stringify(this.lastCurrentValues)) {
      this._setAttributes('currentValues', values)
      this._updateOptions()
      this._getSlider().set(values)
      const sliderValues = this._getSlider().get().map((value) => parseInt(value))
      if (JSON.stringify(values) !== JSON.stringify(sliderValues)) {
        this.setProp('currentValues', sliderValues, options)
      } else if (!options.silent) { this.events.emit('change', values) }
    }
    return this
  }

  /**
   * Set options
   * @param {RangeOptionData[]} values
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setOptions (values, options = {}) {
    const configValues = {}
    if (values && values.length > 0) {
      const selectedValues = values.map((option, index) => ({ ...option, index }))
        .filter((option) => option.selected).map((option) => option.index)
      configValues.rangeMin = 0
      configValues.rangeMax = values.length - 1
      configValues.step = 1
      configValues.margin = 1
      configValues.currentValues = (selectedValues.length > 0)
        ? [selectedValues[0], selectedValues.slice(-1)[0]]
        : [configValues.rangeMin, configValues.rangeMax]
      this._update(configValues, options)
    } else { configValues.currentValues = [...this.props.currentValues] }

    this.setProps(configValues, options)
    return this
  }

  /**
   * Set range min
   * @param {Array} value
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setRangeMin (value, options = {}) {
    this._setAttributes('rangeMin', value)
    this.debouncedUpdateSlider(this.props, options)
    return this
  }

  /**
   * Set range max
   * @param {Array} value
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setRangeMax (value, options = {}) {
    this._setAttributes('rangeMax', value)
    this.debouncedUpdateSlider(this.props, options)
    return this
  }

  /**
   * Set margin
   * @param {Array} value
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setMargin (value, options = {}) {
    this._setAttributes('margin', value)
    this.debouncedUpdateSlider(this.props, options)
    return this
  }

  /**
   * Set step
   * @param {Array} values
   * @param {Boolean} options.silent - If true, does not fire events
   * @returns {Component} self instance
   */
  setStep (value, options = {}) {
    this._setAttributes('step', value)
    this.debouncedUpdateSlider(this.props, options)
    return this
  }

  _init () {
    this.debouncedUpdateSlider = debounce((values, options) => this._update(values, options), 100)
    this.props.currentValues = this.props.currentValues || [this.props.rangeMin, this.props.rangeMax]
    this._initSlider()
    this._updateLabels()
  }

  _initSlider () {
    this._getSlider().on('slide', values => {
      values = values.map(x => parseInt(x))
      this._updateLabels(values)
      this.events.emit('slide', values)
    })

    this._getSlider().on('change', values => {
      values = values.map(x => parseInt(x))
      this.setProp('currentValues', values)
    })

    return this
  }

  _setAttributes (propName, value) {
    this._getPropInstance(propName).setValueToElementAttributes(this.element, value)
  }

  _update (values = this.props, options) {
    const configValues = {
      range: { min: values.rangeMin, max: values.rangeMax },
      step: values.step,
      margin: (values.margin != null) ? values.margin : values.step,
      start: values.currentValues || [values.rangeMin, values.rangeMax]
    }

    this._getSlider().updateOptions(configValues, false)
    this.setProp('currentValues', this._getSlider().get(), options)
    return this
  }

  _updateOptions (options = this.props.options) {
    if (options) {
      const currentValues = this.props.currentValues
      options.forEach((option, index) => (option.selected = (index >= currentValues[0] && index <= currentValues.slice(-1)[0])))
    }
  }

  _updateLabels (values = this.props.currentValues) {
    if (values) {
      const labelElements = this._getLabelElements()
      const options = this.props.options
      const [minValue, maxValue] = [values[0], values.slice(-1)[0]]
      if (labelElements.min) { labelElements.min.innerText = (options && (options[minValue] || {}).text) || minValue }
      if (labelElements.max) { labelElements.max.innerText = (options && (options[maxValue] || {}).text) || maxValue }
    }
  }

  _destroySlider () {
    if (this.slider) {
      this.slider.off()
      this.slider.destroy()
      delete this.slider
    }
    return this
  }

  remove () {
    this._destroySlider()
  }

  _getSliderElement () {
    this.sliderElement = this.sliderElement || this.element.querySelector(`.${classNames.slider}`)
    return this.sliderElement
  }

  _getSliderHelper () {
    return NoUiSlider.create(this._getSliderElement(), {
      range: {
        min: this.props.rangeMin,
        max: this.props.rangeMax
      },
      step: this.props.step,
      margin: this.props.margin,
      start: this.props.currentValues,
      connect: true,
      orientation: 'horizontal',
      behaviour: 'drag-tap'
    })
  }

  _getSlider () {
    this.slider = this.slider || this._getSliderHelper()
    return this.slider
  }

  _getLabelElements () {
    this.labelElements = this.labelElements || {
      min: this.element.querySelector(`.${classNames.currentMinValue}`),
      max: this.element.querySelector(`.${classNames.currentMaxValue}`)
    }
    return this.labelElements
  }
}

/**
 * Range content
 *
 */

registerComponent(Range, definition.name, definition)
