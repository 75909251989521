import Component from '../../../js/core/component/component'
import Backdrop from '../backdrop/main'
import { registerComponent } from '../../../js/core/component/component-directory'
import { getData, elementFromString } from '../../../js/document/html-helper'
import Img from '../img/main'
import { ImgBoxBodyTemplate } from './img-box-body.template'

const definition = {

  name: 'c-img-box',
  props: [
    {
      name: 'open',
      attr: '.in',
      type: 'boolean',
      defaultValue: false
    }
  ],
  actionElements: true
}

const componentQueries = {
  zoomInButtonAttribute: '[data-img-box__buttons--zoom--in]',
  zoomOutButtonAttribute: '[data-img-box__buttons--zoom--out]',
  cancelButtonAttribute: '[data-img-box__close-button]',
  imageAttribute: '[data-img-box__image]'
}

const zoomArray = [
  'zoom1x',
  'zoom2x',
  'zoom4x',
  'zoom8x'
]

/**
 * Image Box content
 *
 */
export default class ImgBox extends Component {
  /**
   * Creates a new Image Box behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.settings = {
      classNames: {
        opened: 'in',
        opening: 'is-opening',
        closing: 'is-closing',
        loading: 'is-loading',
        loaded: 'is-loaded'
      },
      utilitiesClassNames: {
        hiddenFrom: 'u-hidden--from@BP'
      },
      attributes: {
        action: 'data-c-img-box__action',
        id: 'data-c-img-box__id'
      }
    }

    this.opts = getData(element)

    this.backdrop = null
    this.box = null

    this.clicking = false

    element[this.name].remove = this.remove.bind(this)
    element[this.name].open = this.open.bind(this)
    element[this.name].close = this.close.bind(this)
    element[this.name].toggle = this.toggle.bind(this)

    this.element = element
  }

  setOpen (value) { return value ? this._open() : this._close() }

  open () { return this.setProp('open', true) }

  close () { return this.setProp('open', false) }

  async _open () {
    return new Promise((resolve) => {
      this.box = this.createBox()
      this.element.appendChild(this.box)
      Component.initDocumentComponentsFromAPI(this.element)
      Component.initComponentActionElements(this.element)
      Img.createInstancesOnDocument(this.element)
      this.bindZoomButton()
      this.events.emit('open')
      this._openBackdrop()
      this.element.classList.add(this.settings.classNames.opened)

      setTimeout(() => {
        this.events.emit('opened')
        resolve()
      }, this._transitionTime)
    })
  }

  createBox () {
    return elementFromString(
      ImgBoxBodyTemplate({
        sync: false,
        ratio: '16:9',
        resolvedSrc: this.opts.imgBoxResolvedSrc,
        resolve: 'intersect',
        attributes: { 'data-img-box__image': '' },
        placeholderSrc: this.opts.imgBoxPlaceholderSrc,
        title: '',
        dynamicRatios: [],
        extraClasses: 'c-img-box__image',
        isZoomable: this.opts.imgBoxIsZoomable,
        keepOriginalSize: this.opts.imgBoxKeepOriginalSize
      })
    )
  }

  _openBackdrop () {
    this.backdrop = Backdrop()
    this.backdrop.open()
    this.backdrop.element.addEventListener('click', () => this.close())
  }

  async _close () {
    return new Promise((resolve) => {
      this.events.emit('close')

      this.element.classList.remove(this.settings.classNames.opened)
      this.element.classList.add(this.settings.classNames.closing)
      this._closeBackdrop()

      setTimeout(() => {
        this.element.classList.remove(this.settings.classNames.closing)
        this.events.emit('closed')
        resolve()
      }, this._transitionTime)
    })
  }

  _closeBackdrop () {
    this.backdrop.element.removeEventListener('click', () => this.close())
    this.backdrop.close(this.backdrop.destroy)
    this.element.removeChild(this.element.firstElementChild)
  }

  toggle () {
    return this.props.open ? this.close() : this.open()
  }

  remove () {
    this.close().then(() => {
      delete this.element[this.name]
    })
  }

  // Zoomable buttons logic
  bindZoomButton () {
    this.zoomOutButton = null
    this.zoomInButton = null
    this.imageComponent = null
    this.cancelButton = null
    this.currentZoom = 0
    this.zoomOutButton = this._getZoomOutButton()
    this._disable(this.zoomOutButton)

    this.zoomInButton = this._getZoomInButton()
    this.imageComponent = this._getImageComponent()
    this.cancelButton = this._getCancelButton()
    if (this.zoomOutButton) {
      this.zoomOutButton.addEventListener('click', () => {
        this.zoomOut()
      })
    }

    if (this.zoomInButton) {
      this.zoomInButton.addEventListener('click', () => {
        this.zoomIn()
      })
    }

    if (this.imageComponent) {
      this.imageComponent.addEventListener('mousedown', (e) => {
        e.preventDefault()
        this.previousX = e.clientX
        this.previousY = e.clientY
        this.clicking = true
      })

      this.imageComponent.addEventListener('touchstart', (e) => {
        e.preventDefault()
        this.previousX = e.touches[0].clientX
        this.previousY = e.touches[0].clientY
        this.clicking = true
      })

      this.imageComponent.addEventListener('mousemove', (e) => {
        if (this.clicking) {
          e.preventDefault()
          this.imageComponent.scrollLeft = this.imageComponent.scrollLeft + (this.previousX - e.clientX)
          this.imageComponent.scrollTop = this.imageComponent.scrollTop + (this.previousY - e.clientY)
          this.previousX = e.clientX
          this.previousY = e.clientY
        }
      })

      this.imageComponent.addEventListener('touchmove', (e) => {
        if (this.clicking) {
          e.preventDefault()
          this.imageComponent.scrollLeft = this.imageComponent.scrollLeft + (this.previousX - e.touches[0].clientX)
          this.imageComponent.scrollTop = this.imageComponent.scrollTop + (this.previousY - e.touches[0].clientY)
          this.previousX = e.touches[0].clientX
          this.previousY = e.touches[0].clientY
        }
      })

      this.imageComponent.addEventListener('mouseleave', () => {
        this.clicking = false
      })

      this.imageComponent.addEventListener('touchleave', () => {
        this.clicking = false
      })
    }

    if (this.cancelButton) {
      this.cancelButton.addEventListener('click', (e) => {
        this.close()
      })
    }

    document.addEventListener('mouseup', () => {
      this.clicking = false
    })

    document.addEventListener('touchend', () => {
      this.clicking = false
    })
  }

  zoomOut () {
    const relToMaxVert = this._getRelativeVerticalScroll()
    const relToMaxHoriz = this._getRelativeHorizontalScroll()

    if (this.currentZoom > 0) {
      this.imageComponent.classList.remove(...zoomArray)
      this.imageComponent.classList.add(zoomArray[--this.currentZoom])
    }

    if (this.zoomOutButton && this.zoomInButton) {
      this._enable(this.zoomOutButton)
      this._enable(this.zoomInButton)

      if (this.currentZoom === 0) {
        this._disable(this.zoomOutButton)
      }
    }

    this._setScroll(relToMaxVert, relToMaxHoriz)
  }

  zoomIn () {
    const relToMaxVert = this.currentZoom !== 0 ? this._getRelativeVerticalScroll() : 0.5
    const relToMaxHoriz = this.currentZoom !== 0 ? this._getRelativeHorizontalScroll() : 0.5

    if (this.currentZoom < zoomArray.length - 1) {
      this.imageComponent.classList.remove(...zoomArray)
      this.imageComponent.classList.add(zoomArray[++this.currentZoom])
    }

    if (this.zoomOutButton && this.zoomInButton) {
      this._enable(this.zoomOutButton)
      this._enable(this.zoomInButton)

      if (this.currentZoom === zoomArray.length - 1) {
        this._disable(this.zoomInButton)
      }
    }

    this._setScroll(relToMaxVert, relToMaxHoriz)
  }

  _getRelativeVerticalScroll () {
    return this.imageComponent.scrollTop / (this.imageComponent.scrollHeight - this.imageComponent.clientHeight)
  }

  _getRelativeHorizontalScroll () {
    return this.imageComponent.scrollLeft / (this.imageComponent.scrollWidth - this.imageComponent.clientWidth)
  }

  _setScroll (relToMaxVert, relToMaxHoriz) {
    this.imageComponent.scrollTop = (this.imageComponent.scrollHeight - this.imageComponent.clientHeight) * relToMaxVert
    this.imageComponent.scrollLeft = (this.imageComponent.scrollWidth - this.imageComponent.clientWidth) * relToMaxHoriz
  }

  _getZoomInButton () {
    const zoomInButton = this.zoomInButton || this.element.querySelector(componentQueries.zoomInButtonAttribute)
    return zoomInButton
  }

  _getZoomOutButton () {
    const zoomOutButton = this.zoomOutButton || this.element.querySelector(componentQueries.zoomOutButtonAttribute)
    return zoomOutButton
  }

  _getImageComponent () {
    const imageComponent = this.imageComponent || this.element.querySelector(componentQueries.imageAttribute)
    return imageComponent
  }

  _getCancelButton () {
    const cancelButton = this.cancelButton || this.element.querySelector(componentQueries.cancelButtonAttribute)
    return cancelButton
  }

  _enable (element) {
    element.classList.remove('disabled')
  }

  _disable (element) {
    element.classList.add('disabled')
  }
}

registerComponent(ImgBox, definition.name, definition)
