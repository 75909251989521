import { encodeAttributes } from '../../../js/helpers/string'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { FloatingBoxTemplate } from '../../components/floating-box/c-floating-box.template'
import { FloatingBoxHeaderTemplate } from '../../components/floating-box/c-floating-box__header.template'
import { FloatingBoxBodyTemplate } from '../../components/floating-box/c-floating-box__body.template'
import { FloatingBoxFooterTemplate } from '../../components/floating-box/c-floating-box__footer.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The PhoneInputData contains all data needed to hydrate a PhoneInputTemplate view
 *
 * @typedef {Object}              PhoneInputData
 *
 */
export const defaultPhoneInputData = {
  id: 'my-phone-input',
  state: '',
  required: false,
  disabled: false,
  highlighted: false,
  hiddenLabel: false,
  hasClearButton: false,
  hideRequiredSymbol: false,
  value: '',
  placeholder: 'Enter a phone number',
  dialSymbol: '+'
}

export const PhoneInputTemplate = (d) => {
  d = { ...defaultPhoneInputData, ...d }

  const firstCountry = d.countries && d.countries.length >= 1 ? d.countries[0] : null

  return `
    <div class="c-phone-input
            ${d.disabled ? 'is-disabled' : ''}
            ${d.state ? `has-${d.state}` : ''}
            ${d.required ? 'is-required' : ''}
            ${d.highlighted ? 'c-phone-input--highlighted' : ''}
            ${d.hideRequiredSymbol ? 'c-phone-input--without-required-symbol' : ''}
            ${d.extraClasses ? d.extraClasses : ''}
            "
            ${d.required ? 'required' : ''}
            data-js-component="c-phone-input"
            data-country="${firstCountry !== null ? firstCountry.iso2 : ''}"
            data-dial="${d.dialSymbol + (firstCountry !== null ? firstCountry.dial : '')}"
            data-pattern="${firstCountry !== null ? firstCountry.pattern : ''}"
            ${d.messageRequired && d.messageRequired !== '' ? `data-message-required="${d.messageRequired}"` : ''}
            ${d.messageInvalid && d.messageInvalid !== '' ? `data-message-pattern="${d.messageInvalid}"` : ''}
            ${d.attributes ? encodeAttributes(d.attributes) : ''}
            >

        ${d.hiddenLabel
          ? `<label aria-hidden="true" aria-label="${d.label}" for="${d.id}"></label>`
          : d.label
            ? `<label class="c-phone-input__label e-form-label" for="${d.id}">${d.label}</label>`
            : ''}


        <div class="c-phone-input__wrapper">
          <div class="c-phone-input__country-selector"
            data-c-phone-input__country-id="${firstCountry !== null ? firstCountry.iso2 : ''}"
            data-js-element="c-phone-input__country-selector">
              <span class="m-country-flag c-phone-input__country-flag" data-m-country-flag__country-id="${firstCountry !== null ? firstCountry.iso2 : ''}"></span>
              <span class="c-phone-input__chevron m-icon m-icon--chevron-down"></span>
          </div>

          ${TextboxTemplate({
            type: 'tel',
            hiddenLabel: true,
            hiddenMessages: true,
            hasClearButton: false,
            extraClasses: 'c-phone-input__number',
            elementAttributes: d.elementAttributes,
            attributes: {
              'data-js-element': 'c-phone-input__number'
            },
            id: d.id,
            placeholder: d.placeholder,
            required: d.required,
            disabled: d.disabled,
            value: d.value
          })}

            ${FloatingBoxTemplate({
              opened: false,
              floatsFrom: 'sm',
              extraClasses: 'c-phone-input__country-list'
            },
              // floating box content --- start
              FloatingBoxHeaderTemplate({}, '') +
              FloatingBoxBodyTemplate({},
                `<div>
                ${d.countries
                  ? d.countries.map(country =>
                    `<div class="c-phone-input__country-item
                             ${country.isActive ? 'is-active' : ''}"
                           data-c-phone-input__country-id=${country.iso2}
                           data-c-phone-input__country-dial=${d.dialSymbol + country.dial}
                           data-c-phone-input__country-pattern=${country.pattern}>
                        <span class="m-country-flag c-phone-input__country-flag" data-m-country-flag__country-id="${country.iso2}"></span>
                        <span class="c-phone-input__country-name">${country.name}</span>
                        <span class="c-phone-input__country-dial">${d.dialSymbol + country.dial}</span>
                      </div>`
                    ).join('')
                  : ''}
                </div>`) +
              FloatingBoxFooterTemplate({},
                BtnTemplate({
                  text: 'close',
                  variant: 'flat-neutral'
                }))
              // floating box content --- end
            )}
        </div>

        ${d.hiddenMessages
          ? ''
          : `<div class="c-phone-input__messages">
            ${d.messages
              ? d.messages.map(message => `
                <div class="c-phone-input__message">
                    <i class="m-icon m-icon--warning-circle"></i>
                    <span>${message}</span>
                </div>`)
              : ''}
        </div>
        `}
    </div>
    `
}
