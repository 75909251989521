/**
 * The LoaderData contains all data needed to hydrate a Loader view
 *
 * @typedef {Object}          LoaderData
 *
 * @property {String}        [size]         - Component size, eg. undefined or tiny
 * @property {String}        [extraClasses] - Extra classes to append to the view
 */
export const defaultLoaderData = {
  size: '',
  extraClasses: ''
}

export const LoaderTemplate = (d = {}) => {
  d = { ...defaultLoaderData, ...d }

  return `
<i class="c-loader
    ${d.size ? `c-loader--${d.size}` : ''}
    ${d.extraClasses ? d.extraClasses : ''}">
</i>`
}
