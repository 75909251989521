/**
 * The PhoneInputMessagesTemplate contains all the data needed to hydrate a Messages component
 *
 * @typedef {Object}      PhoneInputMessagesTemplate
 *
 * @property {String}     extraClasses      - Extra classes if needed
 */

export const defaultPhoneInputMessagesData = {
  extraClasses: ''
}

export const PhoneInputMessagesTemplate = (d) => `
  ${d
    ? `
        ${d.map(message => `
          <div class="c-phone-input__message">
            <i class="m-icon m-icon--warning-circle"></i>
            <span>${message}</span>
          </div>
    `).join('')}
      `
    : ''}
`
