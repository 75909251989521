import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { getData } from '../../../js/document/html-helper'
import { tns } from 'tiny-slider/src/tiny-slider'
import { debounce } from '../../../js/utils'
import { documentObserver, observerAPI } from '../../../js/document/intersector'
import { SliderTemplate } from '../slider/c-slider.template'
import { ImgTemplate } from '../img/c-img.template'
import { VideoTemplate } from '../video/c-video.template'
import breakpoints from '../../../js/document/breakpoints'
import registeredEvents from '../../../js/helpers/registered-events'

// Ensure other child component APIs are loaded on time
import Img from '../img/main'

const selectorNames = {
  galleryItem: '.c-slider__inner-item',
  gallerySlider: 'c-slider__slider',
  galleryModalContent: 'c-modal-v2__body',
  galleryModalFooter: 'c-modal-v2__footer',
  modal: '[data-c-slider__modal]',
  modalApi: 'c-modal-v2',
  videoApi: 'c-video',
  video: 'c-video-youtube',
  sliderContent: 'c-slider__content'
}

const imgComponentAPI = 'c-img'
const definition = {
  name: 'c-slider',
  props: [{
    name: 'index',
    type: 'number'
  }],
  actionElements: true
}

const options = {
  speed: 500,
  mouseDrag: true,
  arrowKeys: true,
  autoplayHoverPause: true,
  loop: true,
  nav: true,
  startIndex: 0,
  items: 1,
  responsive: null,
  resolveOnIntersect: true
  // autoplay: true,
  // autoplayTimeout: 5000
}

const attr = {
  track: 'data-track'
}

/**
 * Choice list content
 *
 */
export default class Slider extends Component {
  /**
   * Creates a new choice list behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    if (this.element.getAttribute('clickable')==="true"){
      this.modal = this.element.querySelector(selectorNames.modal)
      if (this.modal) {
        this.modalContent = this.modal.querySelector(`.${selectorNames.galleryModalContent}`)
        this.modalFooter = this.modal.querySelector(`.${selectorNames.galleryModalFooter}`)
      }
      this._attachEvents()
      this._setGalleryListeners()
      this.modalContentRendered = false
      this.sliderInitialized = false
      this.sliderIndex = 0
    }

    this.imgElements = Array.from(this.element.querySelectorAll(`.c-slider__item [data-js-component*="${imgComponentAPI}"]`))

    this._debouncedcheckSlides = debounce((...args) => this._checkSlides(args), 200)
    this._getSlideApi()
    this.navItems = this.element.querySelectorAll('.tns-nav [data-nav]')
    this._updateNavIndexes()
    this._updateCenterSlide()

    this._attachModalEvents()

    if (this._getOptions().resolveOnIntersect || this.imgElements.length === 1) {
      const observer = documentObserver()
      observer.observe(this.element)
      this.element[observerAPI].events.on('enter', () => {
        this._debouncedcheckSlides(this._getOptions().startIndex)
        observer.unobserve(this.element)
      })
    }

    element[this.name]['prev'] = () => this.slider.goTo('prev')
    element[this.name]['next'] = () => this.slider.goTo('next')
    element[this.name]['destroy'] = () => this.destroy()

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })


  }

  /**
   * If whole slider is clickable, it will only create 1 modal for all items,
   * if it's not, it will create 1 omdal for every video in the item's list
   */
  _attachModalEvents () {
    const sliderElementsClickables = this.element.querySelectorAll('[data-c-modal__action="open"]')
    sliderElementsClickables.forEach(itemSlider => {
      const modalId = itemSlider.getAttribute('data-c-modal__id')
      if (modalId) {
        const sliderItemModal = this.element.querySelector('#'+modalId)
        
        if (sliderItemModal) {
          const sliderItemModalApi = sliderItemModal[selectorNames.modalApi]
          itemSlider.addEventListener('click', ev => {

            sliderItemModalApi.open()
          })
        }
      }
    })
  }


  /**
   * Modal pop-up
   */
  
  _attachEvents () {
    if (this.modal) {
      this.modalApi = this.modal[selectorNames.modalApi]
      this.modalApi.events.on('onScreen', this._isOpened, this)
      this.modalApi.events.on('close', this._isClosed, this)
    }
  }

  _isClosed () {
    this.videos = this.element.querySelectorAll('.' + selectorNames.videoApi)
    for (var i in this.videos) {
      const videoElement = this.videos[i]
      var video = videoElement[selectorNames.videoApi]
      if (video) video.pauseVideo()
    }
  }

  _isOpened () {
    const sliderTransitionElement = this.modalContent.querySelector(`.${selectorNames.sliderContent}`)
    if (sliderTransitionElement) sliderTransitionElement.style.transition = 'none'
    Img.createInstancesOnDocument(this.modalContent)

    if (this.sliderIndex === 0) {
      this._setSliderIndex(1)
    }
    this._setSliderIndex(this.sliderIndex)
    
    if (sliderTransitionElement) sliderTransitionElement.style.transition = null

    this._renderModalFooter()

    if (this.modal && this._getOptions().loop) {
      const items = this.modalContent.querySelectorAll(".c-slider__item")
      items[0].innerHTML = ""
      items[items.length-1].innerHTML = ""
    Component.initDocumentComponentsFromAPI(this.modalContent)

    }

    this.videosInSlider = this.modalContent.querySelectorAll('.' + selectorNames.video)
      if(this.videosInSlider){
        let i = 0
        this.videosInSlider.forEach(item => {
          item.id = item.id + i
          i++
        });
      }
    }

  _renderModalContent () {
    this.modalContent.innerHTML = SliderTemplate({
      id: `${this.element.id}__slider`,
      extraClasses: selectorNames.gallerySlider,
      luminosity: 'dark',
      outerButtons: true,
      attributes: { 'data-loop': true, 'data-resolve-on-intersect': false },
      items: this._getImageElements(),
      track: (this.element.dataset.track ? `${this.element.dataset.track}Open`: null)
    })
    
  }


  _renderModalFooter () {
    const slider = this._getSliderElement()
    const sliderApi = slider['c-slider']
    const descriptions = this._getDescriptions()
    if (slider) {
      this._updateDescription(descriptions[this.sliderIndex])
      sliderApi.events.on('change', (index) => {
        this._updateDescription(descriptions[index])
      }, this)
    }
  }

  _updateDescription (description) {
    this.modalFooter.innerHTML = description
  }

  /**
   * In order to not create more slides than needed and mess Tiny-slider, we control the amount 
   * of slides originaly meant to be with totalNumberSlides
   */
  _getImageElements () {
    let images = []
    const items = this.element.querySelectorAll(selectorNames.galleryItem)
    let totalNumberSlides = 0
    items.forEach( (item) => {
      if (this._getOptions().loop) {

        const itemNumber = (parseInt(item.getAttribute('data-c-slider-inner-item')))
        if (itemNumber === totalNumberSlides) {
          if (item.getAttribute('data-item-gallery-type') === 'video') {
            const image = VideoTemplate({
              videoId: item.getAttribute('data-video-videoId'),
              id: item.getAttribute('data-video-id'),
              apiKey: item.getAttribute('data-video-apiKey'),
              url: item.getAttribute('data-video-url'),
              thumbnail: item.getAttribute('data-image-placeholderSrc')
            })
            images.push(image)
          } else {
            const image = ImgTemplate({
              placeholderSrc: item.getAttribute('data-image-placeholderSrc'),
              resolvedSrc: item.getAttribute('data-image-resolvedSrc'),
              ratio: item.getAttribute('data-image-ratio')
            })
            images.push(image)
          }
            totalNumberSlides++
        }

      } else {
          if (item.getAttribute('data-item-gallery-type') === 'video') {
            const image = VideoTemplate({
              videoId: item.getAttribute('data-video-videoId'),
              id: item.getAttribute('data-video-id'),
              apiKey: item.getAttribute('data-video-apiKey'),
              url: item.getAttribute('data-video-url'),
              thumbnail: item.getAttribute('data-image-placeholderSrc')
            })
            images.push(image)
          } else {
            const image = ImgTemplate({
              placeholderSrc: item.getAttribute('data-image-placeholderSrc'),
              resolvedSrc: item.getAttribute('data-image-resolvedSrc'),
              ratio: item.getAttribute('data-image-ratio')
            })
            images.push(image)
          }
      }
        
      
    })
    return images
  }

  _getDescriptions () {
    let descriptions = []
    const items = this.element.querySelectorAll(selectorNames.galleryItem)
    items.forEach(item => {
      const description = item.getAttribute('data-image-description')
      descriptions.push(description)
    })
    return descriptions
  }

  _setSliderIndex (index) {
    const slider = this._getSliderElement()
    if (slider) {
      slider['c-slider'].setProp('index', index, {forceUpdate: true})
      this.sliderApi = slider['c-slider']
      this.sliderApi.events.on('change', this._isClosed, this)
    }
  }

  _setGalleryListeners () {
    this.element.addEventListener('click', (ev) => {
      ev.preventDefault()
      if (!this.modalContentRendered) {
        this._renderModalContent()
        Component.initDocumentComponentsFromAPI(this.modalContent)
        Component.initComponentActionElements(this.modalContent)
        this.modalContentRendered = true
      }
      const target = ev.target && ev.target.closest(selectorNames.galleryItem)
      if (target) {
        this.sliderIndex = parseInt(target.dataset.cSliderInnerItem)
      }
    })
    
    
  }

  _getSliderElement () {
    this.sliderElement = this.sliderElement || this.element.querySelector(`.${selectorNames.gallerySlider}`)
    this.videos = this.element.querySelectorAll('.' + selectorNames.videoApi)
    return this.sliderElement
  }

   /**
   * End modal pop-up
   */



  /**
   * Gets the slider item's index. (If more than one item per page, then it's the first element shown)
   *
   * @returns {Number} index.
   */
  getIndex () {
    return this._getIndex()
  }

  /**
   * Sets the slider item's index
   *
   * @param {Number} opc - The desire index to focus on.
   * @returns {Promise}
   */
  setIndex (opc) {
    return this._getSlideApi().goTo(opc)
  }

  _updateNavIndexes() {
    if (!this.navItems || !this.navItems.length) return this

    this.navItems.forEach(item => item.removeAttribute('data-relative-index'));

    let prevRelativeIndex = 0;
    let nextRelativeIndex = 0;
    let prevCurrentItem = [...this.navItems].find(item => item.matches(':not([tabindex="-1"])'));
    let nextCurrentItem = [...this.navItems].find(item => item.matches(':not([tabindex="-1"])'));

    while (prevCurrentItem.previousSibling) {
      prevCurrentItem = prevCurrentItem.previousSibling;
      if (!prevCurrentItem.matches('[hidden]')) {
        prevRelativeIndex--;
        prevCurrentItem.setAttribute('data-relative-index', prevRelativeIndex);
      }
    }
    while (nextCurrentItem.nextSibling) {
      nextCurrentItem = nextCurrentItem.nextSibling;
      if (!nextCurrentItem.matches('[hidden]')) {
        nextRelativeIndex++;
        nextCurrentItem.setAttribute('data-relative-index', nextRelativeIndex);
      }
    }
  }

  /**
   * When center is set to true, and the amount of items differ between breakpoints, the slider
   * gets stuck between two slides if we don't refresh
   */
  _centerRefresh () {
    if (this._getOptions().center) {
      this.slider.refresh()
    }
  }

  /**
   * When center is set to true, adds an active class so this can be styled separately
   */
  _updateCenterSlide () {
    if (this._getOptions().center) {
      const items = Array.from(this._getSlideInfo().slideItems)
      if (!items) return
      const index = this._getSlideInfo().index
      items.forEach(item => item.classList.remove('c-slider__item--active'));
      items[index].classList.add('c-slider__item--active');
    }
  }

  _getSlideItems () {
    this.slideItems = this.slideItems || Array.from(this.element.querySelectorAll('.c-slider__content .c-slider__item'))
    return this.slideItems
  }

  _setEventEmitters (slider) {
    if (!slider) return
    slider.events.on('indexChanged', (info) => {
      const index = this._getIndex(info)
      this._updateNavIndexes()
      this._updateCenterSlide()
      this.events.emit('change', index)
      this._debouncedcheckSlides(index)
    })

    slider.events.on('transitionEnd', (info) => {
      const index = this._getIndex(info)
      this.props.index = index
      this.events.emit('changeEnd', index)
    })

    slider.events.on('newBreakpointEnd', (info) => {
      this._centerRefresh()
      this._debouncedcheckSlides(this._getIndex(info))
    })
  }

  /**
   * Gets the real item's index from the slider
   *
   * @param {Object} info - Slider info
   * @returns {Number} index
   */
  _getIndex (info = this._getSlideInfo()) {
    return ((info.index - info.cloneCount) + info.slideCount) % info.slideCount
  }

  /**
   * Check previous and next slides in case images need to be resolved
   *
   * @param {Number} index - Slider index
   * @param {Number} itemsPerPage - How many items does the slider have by page.
   */
  _checkSlides (index = this.getIndex(), itemsPerPage = (this._getSlideInfo().items || this._getItemsPerPage())) {
    if (this.imgElements.filter((element) => !element.classList.contains('is-resolved')).length > 0) {
      const items = []
      index = Number(index)
      const [edgeLeft, edgeRight] = [(-itemsPerPage + index), ((itemsPerPage * 2) + index)]
      if (this._getOptions().loop && edgeLeft < 0) { items.push(...this._getSlideItems().slice(edgeLeft)) }
      items.push(...this._getSlideItems().slice((edgeLeft < 0) ? 0 : edgeLeft, edgeRight))
      Array.from(new Set(items)).forEach((item) => {
        Array.from(item.querySelectorAll(`[data-js-component*="${imgComponentAPI}"]`)).map((el) => el[imgComponentAPI])
          .forEach((imgApi) => imgApi && imgApi.resolve())
      })
    }
  }

  /**
   * If the slider is not created, we calculate the items per page based on the information provided by 'options'
   *
   * @param {Number} items - Items per page
   */
  _getItemsPerPage () {
    const responsive = this._getOptions().responsive
    let items = this._getOptions().items
    if (responsive) {
      const viewportWidth = window.innerWidth
      Object.entries(responsive).forEach(([size, itemObj]) => {
        if (viewportWidth >= Number(size)) { items = itemObj.items }
      })
    }
    return items
  }

  _getOptionsHelper () {
    const opts = {
      ...options,
      ...getData(this.element),
      container: this.element.querySelector('.c-slider__content'),
      controlsContainer: this.element.querySelector('.c-slider__buttons')
    }
    if (opts.responsive && typeof opts.responsive === 'string') {
      opts.responsive = opts.responsive.split(',').map((breakpointItem) => breakpointItem.split(':'))
        .reduce((obj, [breakpoint, item]) => {
          const isDisabled = (item === 'disable')
          const items = (!isDisabled && item) ? { items:Number(item) } : null
          return {
            ...obj,
            [breakpoints[breakpoint]]: {
              disable: isDisabled,
              ...items
            }
          }
        }, {})
    }

    return opts
  }

  _getOptions () {
    this.options = this.options || this._getOptionsHelper()
    return this.options
  }

  _setOptions (options) {
    this.options = {...this._getOptions(), ...options}
    return this.options
  }

  _createSlider () {
    this.numSlides = this._getSlideItems().length

    let opts = this._getOptions()
    opts.onInit = () => {
      Img.createInstancesOnDocument(this.element)
    }

    const slider = tns(opts)
    this._checkIfEdgeNavigator()
    this._setEventEmitters(slider)
    return slider
  }

  destroy () {
    if (this.slider && this.slider.destroy) this.slider.destroy()
  }

  _getSlideApi () {
    this.slider = this.slider || this._createSlider()
    return this.slider
  }

  _getSlideInfo () {
    const info = this._getSlideApi()
                  && this._getSlideApi().getInfo
                  && this._getSlideApi().getInfo()
    return info || {}
  }

  _checkIfEdgeNavigator () {
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
      const slideItems = Array.from(this.element.querySelectorAll('.c-slider__content .c-slider__item'))
      const slideItemsLength = slideItems.length
      slideItems.forEach((item) => (item.style.width = (Math.floor(100 / slideItemsLength * 100) / 100) + '%'))
    }
  }
}

registerComponent(Slider, definition.name, definition)
