import { getTokenClass } from '../../../js/helpers/tokens'
import { encodeAttributes } from '../../../js/helpers/string'
import { ModalV2HeaderTemplate } from './c-modal-v2__header.template'
import { ModalV2BodyTemplate } from './c-modal-v2__body.template'
import { ModalV2FooterTemplate } from './c-modal-v2__footer.template'

// Import tokens
const tokensShared = require('./data/c-modal-v2__tokens.json')['c-modal-v2']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal-v2/data/c-modal-v2__tokens.json`)['c-modal-v2'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultModalData = {
  size: 'medium',
  id: '',
  async: false,
  isFixHeight: false,
  extraClasses: '',
  closable: true,
  openOnLoad: false,
  hiddenBackdrop: false,
  attributes: null,
  headerTag: 'h2',
  title: '',
  bodyContent: '',
  footerContent: ''
}

export const ModalV2Template = (d) => {
  d = { ...defaultModalData, ...d }
  return `
<div ${d.id ? `id="${d.id}"` : ''}
  class="c-modal-v2 
    ${getTokenClass('size', d.size, tokens)} 
    ${d.async ? 'c-modal-v2--async' : ''} 
    ${d.isFixHeight ? 'is-fix-height' : ''} 
    ${d.preHeaderContent ? 'has-preheader' : ''} 
    ${d.title ? 'has-title' : ''} 
    ${d.logoUrl ? 'has-logo' : ''} 
    ${d.extraClasses}"
  data-js-component="c-modal-v2" 
  ${d.closable ? 'data-c-modal-v2--closable' : ''}
  ${d.openOnLoad ? 'data-c-modal-v2--open-on-load' : ''}
  ${d.hiddenBackdrop ? 'data-c-modal-v2--hidden-backdrop' : ''}
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
   ${ModalV2HeaderTemplate(d)}
   ${ModalV2BodyTemplate(d)}
   ${ModalV2FooterTemplate(d)}
 </div>
`
}
