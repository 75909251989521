/**
 * The SliderData contains all data needed to hydrate a Slider view
 *
 * @typedef {Object}            SliderData
 *
 * @property {String}           id               - The slider id
 * @property {String}           luminosity       - The slider luminosity
 * @property {Boolean}          outerNavigation  - True if the slider uses outer navigation
 * @property {Boolean}          outerButtons     - True if the slider uses outer buttons, but inner navigation
 * @property {Boolean}          barNavigation    - True if the slider uses bar navigation
 * @property {Boolean}          noButtons        - True if the buttons should be hidden
 * @property {Boolean}          withGradient     - True if the slider uses gradient to overflowed content
 * @property {SliderItemData[]} items            - The slider items content
 * @property {String}           [extraClasses]   - Extra classes to be appended
 * @property {Object}           [attributes]     - Attributes to be appended
 */

/**
 * The SliderItemData contains all data needed to hydrate a SliderItem view
 *
 * @typedef {Object}            SliderItemData
 *
 * @property {String}           html             - The item HTML content
 */

import { encodeAttributes } from '../../../js/helpers/string'
import { BtnTemplate } from '../btn/c-btn.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-slider__tokens.json')['c-slider']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/slider/data/c-slider__tokens.json`)['c-slider'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultSliderData = {
  id: '',
  luminosity: '',
  outerNavigation: false,
  outerButtons: false,
  barNavigation: false,
  noButtons: false,
  withGradient: false,
  stretch: false,
  items: [],
  extraClasses: '',
  attributes: '',
  track: ''
}

export const SliderTemplate = (d) => {
  d = { ...defaultSliderData, ...d }
  return `
<div id="${d.id}"
     class="c-slider js-sundio-slider
            ${d.luminosity ? getTokenClass('luminosity', d.luminosity, tokens) : getTokenClass('luminosity', 'default', tokens)}
            ${d.outerNavigation ? 'c-slider--outer-navigation' : ''}
            ${d.outerButtons ? 'c-slider--outer-buttons' : ''}
            ${d.barNavigation ? 'c-slider--bar-navigation' : ''}
            ${d.noButtons ? 'c-slider--no-buttons' : ''}
            ${d.withGradient ? 'c-slider--with-gradient' : ''}
            ${d.stretch ? 'c-slider--with-stretch' : ''}
            ${d.extraClasses || ''}"
     ${d.track ? `data-track="${d.track}"` : ''}
     data-js-component="c-slider"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  <div class="c-slider__content">
    ${d.items.map(SliderItemTemplate).join('')}
  </div>

  <div class="c-slider__buttons">
    ${BtnTemplate({
      ...d.button,
      variant: 'icon-only',
      icon: getTokenClass('icons', 'left', tokens) || '',
      extraClasses: 'c-slider__buttons--arrow c-slider__buttons--arrow--prev',
      attributes: { 'data-c-slider__buttons--direction': 'prev' }
    })}
    ${BtnTemplate({
      ...d.button,
      variant: 'icon-only',
      icon: getTokenClass('icons', 'right', tokens) || '',
      extraClasses: 'c-slider__buttons--arrow c-slider__buttons--arrow--next',
      attributes: { 'data-c-slider__buttons--direction': 'next' }
    })}

  </div>
</div>
`
}

export const SliderItemTemplate = (html) => {
  return `<div class="c-slider__item">${html}</div>`
}
