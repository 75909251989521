import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'

// Ensure other child component APIs are loaded on time
require('../btn/main')

const definition = {
  name: 'c-number-stepper',
  props: [
    {
      name: 'value',
      type: 'number'
    },
    {
      name: 'minValue',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'maxValue',
      type: 'number'
    }
  ]
}

/**
 * Number stepper content
 *
 */
export default class NumberStepper extends Component {
  /**
   * Creates a new number stepper behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.inputElement = this.element.querySelector('input[type="number"]')
    this.decrementBtn = this.element.querySelector('[data-c-number-stepper__decrement]')
    this.incrementBtn = this.element.querySelector('[data-c-number-stepper__increment]')
    this.decrementBtnApi = this.decrementBtn['c-btn']
    this.incrementBtnApi = this.incrementBtn['c-btn']
    this._updateBtnStates()
    this._updateInputState()

    // Bind listeners to decrement & increment
    this.decrementBtn.addEventListener('click', (ev) =>
      this.setProp('value', this.props.value - 1)
        .catch(ex => console.warn(ex))
    )
    this.incrementBtn.addEventListener('click', (ev) =>
      this.setProp('value', this.props.value + 1)
        .catch(ex => console.warn(ex))
    )
  }

  _updateBtnStates () {
    if (this.props.minValue !== undefined && this.decrementBtnApi) {
      this.decrementBtnApi.setProp('disabled', this.props.value === this.props.minValue)
    }
    if (this.props.maxValue !== undefined && this.incrementBtnApi) {
      this.incrementBtnApi.setProp('disabled', this.props.value === this.props.maxValue)
    }
  }

  _updateInputState () {
    if (this.decrementBtnApi.element.disabled && this.incrementBtnApi.element.disabled) {
      this.inputElement.classList.add('disabled')
    }
  }

  checkValue (value) {
    if (this.props.minValue !== undefined && value < this.props.minValue) return false
    if (this.props.maxValue !== undefined && value > this.props.maxValue) return false
    return true
  }

  setValue (value, options = { silent: false }) {
    const oldValue = this.inputElement
    this.inputElement.value = value
    this._updateBtnStates()
    this._updateInputState()

    if (!options.silent) {
      this.events.emit('change', value, oldValue)
    }

    return oldValue
  }

  getValue () {
    const value = this._getInputNode().value
    return value ? parseInt(value) : undefined
  }

  checkMinValue (value) {
    return !(this.props.maxValue !== undefined && value > this.props.maxValue)
  }

  setMinValue (value) {
    this.inputElement.setAttribute('min', value)
    this._updateBtnStates()
    this._updateInputState()
  }

  getMinValue () {
    const value = this._getInputNode().getAttribute('min')
    return value ? parseInt(value) : undefined
  }

  checkMaxValue (value) {
    return !(this.props.minValue !== undefined && value < this.props.minValue)
  }

  setMaxValue (value) {
    this.inputElement.setAttribute('max', value)
    this._updateBtnStates()
    this._updateInputState()
  }

  getMaxValue () {
    const value = this._getInputNode().getAttribute('max')
    return value ? parseInt(value) : undefined
  }

  _getInputNode () {
    this.inputElement = this.inputElement || this.element.querySelector('input[type="number"]')
    return this.inputElement
  }
}

registerComponent(NumberStepper, definition.name, definition)
