import { getTokenClass } from '../../../js/helpers/tokens'
import { defaultModalData } from './c-modal.template'

// Import tokens
const tokensShared = require('./data/c-modal__tokens.json')['c-modal']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal/data/c-modal__tokens.json`)['c-modal'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const ModalBodyTemplate = (d) => {
  d = { ...defaultModalData, ...d }
  return `
<div
  class="c-modal__body ${d.async ? 'c-modal__body--async' : ''} m-body 
    ${getTokenClass('body__size', 'default', tokens)} 
    ${getTokenClass('space-stack-children', 'default', tokens)}"> 
     ${d.bodyContent}
 </div>
`
}
