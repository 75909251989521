import Component from '../../../js/core/component/component'
import { BtnTemplate } from '../btn/c-btn.template'
import { registerComponent } from '../../../js/core/component/component-directory'
import { fromCamelCase } from '../../../js/helpers/string'
import { debounce } from '../../../js/utils'

const classNames = {
  attributeSelector: 'data-c-pagination__',
  paginationButton: 'c-pagination__button',
  paginationButtonWrapper: 'c-pagination__list-pages',
  disabled: 'is-disabled',
  active: 'is-active',
  checking: 'is-checking'
}

const definition = {

  name: 'c-pagination',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */
  props: [{
    name: 'disable',
    type: 'boolean',
    attr: '.is-disabled',
    defaultValue: false
  }, {
    name: 'currentPage',
    type: 'number',
    attr: `${classNames.attributeSelector}current-page`,
    defaultValue: 1
  }, {
    name: 'totalPages',
    type: 'number',
    attr: `${classNames.attributeSelector}total-pages`
  }, {
    name: 'urlPattern',
    type: 'string',
    attr: `${classNames.attributeSelector}url-pattern`
  }]
}

/**
 * Pagination content
 *
 */
export default class Pagination extends Component {
  /**
   * Creates a new pagination behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this._init()

    this.element[definition.name].prev = (this.prev).bind(this)
    this.element[definition.name].next = (this.next).bind(this)
  }

  /**
   * Checks current page is positive and !NaN
   *
   * @param {String|Integer} page - The page to set
   * @returns {Pagination} Self instance.
   */
  checkCurrentPage (page) {
    return this._isAllowedValue('currentPage', page)
  }

  /**
   * Checks total pages is positive and !NaN
   *
   * @param {String|Integer} page - The page to set
   * @returns {Pagination} Self instance.
   */
  checkTotalPages (page) {
    return this._isAllowedValue('totalPages', page)
  }

  /**
   * Sets or updates the current page attribute
   *
   * @param {String|Integer} page - The page to set
   * @returns {Pagination} Self instance.
   */
  setCurrentPage (page, options = {}) {
    page = page || (this.props.currentPage = 1)
    if (!options.silent) { this.debouncedPropChanged(page, options) }
    this._changePropAttrs('currentPage', page)
    this._updateCurrentPage(page)
    return this
  }

  /**
   * Sets or updates the total pages attribute
   *
   * @param {String|Integer} page - The page to set
   * @returns {Pagination} Self instance.
   */
  setTotalPages (page, options = {}) {
    page = page || (this.props.totalPages = 1)
    this._changePropAttrs('totalPages', page)
    this._render(options)
    return this
  }

  /**
   * Goes to prev page if exist
   *
   * @returns {Pagination} Self instance.
   */
  prev () {
    const currentPage = this.getProp('currentPage')
    if (currentPage > 1) { this.setProp('currentPage', currentPage - 1, { previous: true }) }
    return this
  }

  /**
   * Goes to next page if exist
   *
   * @returns {Pagination} Self instance.
   */
  next () {
    const { currentPage, totalPages } = this.props
    if (currentPage < totalPages) { this.setProp('currentPage', currentPage + 1, { next: true }) }
    return this
  }

  _init () {
    this.debouncedPropChanged = debounce((currentPage, options) => this.events.emit('change', { currentPage, ...options }), 100)
    this._setListeners()
    this.element.classList.remove(classNames.checking)
  }

  _isAllowedValue (propName, page) {
    const property = this._getPropInstance(propName)
    return (property.isAllowedValue.bind(property)(page) && !isNaN(page) && (page >= 0))
  }

  _changePropAttrs (opc, page) {
    this.element.setAttribute(`${classNames.attributeSelector}${fromCamelCase(opc)}`, page)
  }

  /**
   * Set the listeners for click and prop changes
   * @returns {Pagination} The self instance
   */
  _setListeners () {
    this.element.addEventListener('click', (e) => this._changePage(e))

    return this
  }

  /**
   * Triggers events to inform that the page has changed
   *
   * @param {Event} e - The captured event
   * @returns {Pagination} Self instance
   */
  _changePage (e) {
    const target = e.target && e.target.closest(`.${classNames.paginationButton}`)
    if (target) {
      const targetAction = target.getAttribute(`${classNames.attributeSelector}nav`)

      if (targetAction && this[targetAction] && (typeof this[targetAction] === 'function')) {
        this[targetAction]()
      } else {
        const page = parseInt(target.getAttribute(`${classNames.attributeSelector}page`))
        this.setProp('currentPage', page)
      }
    }

    return this
  }

  /**
   * Triggers events to inform that the page has changed
   *
   * @param {Event} e - The captured event
   * @returns {Pagination} Self instance
   */
  _checkButtonsDisable () {
    const { currentPage, totalPages } = this.props
    this._getNavButtonElements().prev.classList[(currentPage < 2) ? 'add' : 'remove'](classNames.disabled)
    this._getNavButtonElements().next.classList[(currentPage >= totalPages) ? 'add' : 'remove'](classNames.disabled)

    return this
  }

  /**
   * Triggers events to inform that the page has changed
   *
   * @param {Event} e - The captured event
   * @returns {Pagination} Self instance
   */
  _updateCurrentPage (currentPage = this.getProp('currentPage')) {
    this._getPaginationButtonElements().forEach((item, index) => {
      const indexTemp = (index + 1) - currentPage
      item.classList[indexTemp === 0 ? 'add' : 'remove'](classNames.active)
      item.setAttribute('data-index', indexTemp)
    })
    this._checkButtonsDisable()

    return this
  }

  /**
   * Changes the HTML according to total pages changes
   *
   * @returns {Pagination} Self instance
   */
  _render (options = {}) {
    const { currentPage, totalPages, urlPattern } = this.props
    let componentHTML = ''

    // Do not show the pagination with a single page
    if (totalPages > 1) {
      for (let index = 1; index <= totalPages; index++) {
        const link = {}
        if (urlPattern !== undefined && urlPattern !== null) {
          link.href = urlPattern.split('{p}').join(index)
        }

        componentHTML += BtnTemplate({
          text: index,
          link,
          variant: 'flat',
          extraClasses: 'c-pagination__button' + (currentPage === index ? ' is-active' : ''),
          attributes: {
            'data-index': index - currentPage,
            'data-c-pagination__page': index
          }
        })
      }
    }

    this._getPaginationButtonWrapper().innerHTML = componentHTML
    this._getPaginationButtonElements(true)
    this._checkButtonsDisable()

    if (currentPage > totalPages) { this.setProp('currentPage', totalPages, { silent: options.silent, forceUpdate: true }) }

    return this
  }

  /* GETTERS */
  _getPaginationButtonWrapper () {
    this.paginationButtonWrapper = this.paginationButtonWrapper || this.element.querySelector(`.${classNames.paginationButtonWrapper}`)
    return this.paginationButtonWrapper
  }

  _getPaginationButtonElements (forceUpdate = false) {
    this.paginationButtonElements = (!forceUpdate && this.paginationButtonElements) ||
      Array.from(this._getPaginationButtonWrapper().querySelectorAll(`.${classNames.paginationButton}`))
    return this.paginationButtonElements
  }

  _getNavButtonElements () {
    this.navButtonElements = this.navButtonElements ||
      Array.from(this.element.querySelectorAll(`[${classNames.attributeSelector}nav]`))
        .reduce((obj, item) => ({ ...obj, [item.getAttribute(`${classNames.attributeSelector}nav`)]: item }), {})
    return this.navButtonElements
  }
}

registerComponent(Pagination, definition.name, definition)
