import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import YouTubePlayer from 'youtube-player'

const DEFINITION = {
  name: 'c-video',
  props: []
}

const classNames = {
  thumbnail: 'c-video-thumbnail',
  thumbnailImage: 'c-video-thumbnail-image',
  video: 'c-video-youtube',
  videoName: 'c-video-thumbnail-info-name',
  videoDuration: 'c-video-thumbnail-info-duration'
}

/**
 * Video content
 *
 */

export default class Video extends Component {
  /**
     * Creates a new video behaviour.
     *
     * @constructor
     * @param {HTMLElement} element - The HTML element.
     * @param {AutocompleteOptions} [options={}] - Options object
     */
  constructor (element, options = {}) {
    super(element, DEFINITION.name)
    this.element = element
    this.thumbnailElement = this.element.querySelector(`.${classNames.thumbnail}`)
    const urlVideo = this.element.querySelector(`.${classNames.video}`).getAttribute('data-video-src')
    const apiKey = this.element.querySelector(`.${classNames.video}`).getAttribute('data-api-key')
    const idVideo = this._getYoutubeIdByUrl(urlVideo)
    const videoElement = this.element.querySelector(`.${classNames.video}`)
    const videoNameElement = this.element.querySelector(`.${classNames.videoName}`)
    const videoDurationElement = this.element.querySelector(`.${classNames.videoDuration}`)
    const thumbnailImageElement = this.element.querySelector(`.${classNames.thumbnailImage}`)
    this._loadYoutubeApi()
    this.youtubePlayer = null
    videoElement.setAttribute('videoid', idVideo)

    if (idVideo !== -1) {
      const videoInfo = this._getVideoInfo(idVideo, apiKey)

      /**
     * Print the HTML
     */
      videoInfo.then(function (result) {
        if (result !== -1) {
          videoNameElement.innerHTML = result[0]
          videoDurationElement.innerHTML = result[1]
          if (thumbnailImageElement.classList.contains('no-original-thumbnail')) {
            thumbnailImageElement.src = result[2]
          }
        } else {
          element.remove()
          if (window.newrelic) {
            window.newrelic.noticeError('[Video] Wrong Youtube API key')
          }
        }
      })
    } else {
      element.remove()
      if (window.newrelic) {
        window.newrelic.noticeError('[Video] Id video not found')
      }
    }

    /* listeners */
    this.thumbnailElement.addEventListener('click', e => {
      this._hideThumbnailPlayVideo()
      this.youtubePlayer = YouTubePlayer(videoElement.getAttribute('id'))
      if (!videoElement.classList.contains('video-loaded')) {
        videoElement.classList.add('video-loaded')
        this.youtubePlayer.loadVideoById(idVideo)
        this._setVideoListeners(this.youtubePlayer)
      } else {
        this.youtubePlayer.playVideo()
      }
    })

    element[this.name].pauseVideo = this.pauseVideo.bind(this)
  }

  pauseVideo () {
    if (this.youtubePlayer) { this.youtubePlayer.stopVideo() }
    this.thumbnailElement.classList.remove('hidden')
  }

  _hideThumbnailPlayVideo () {
    this.thumbnailElement.classList.add('hidden')
  }

  _setVideoListeners (player) {
    player.on('stateChange', (event) => {
      if (event.data === 2) {
        this.thumbnailElement.classList.remove('hidden')
      }
    })
  }

  _loadYoutubeApi () {
    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  /**
   *
   * return Video ID from youtube
   * @param {urlVideo} urlVideo
   */
  _getYoutubeIdByUrl (urlVideo) {
    const regExp = /^.*(youtu.*be.*)\/(watch\?v=|embed\/|shorts|)(.*?((?=[&#?])|$)).*/ // eslint-disable-line
    const match = urlVideo.match(regExp)

    if (match && match[3].length === 11) {
      return match[3]
    } else {
      return -1
    }
  }

  _formatSecondsAsTime (duration) {
    let a = duration.match(/\d+/g)

    /**
     * get separated duration
     */
    if (duration.indexOf('M') >= 0 && duration.indexOf('H') === -1 && duration.indexOf('S') === -1) {
      a = [0, a[0], 0]
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1) {
      a = [a[0], 0, a[1]]
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1 && duration.indexOf('S') === -1) {
      a = [a[0], 0, 0]
    }

    /**
     * set duration per hours, minutes & seconds
     */
    duration = 0

    if (a.length === 3) {
      duration = duration + parseInt(a[0]) * 3600
      duration = duration + parseInt(a[1]) * 60
      duration = duration + parseInt(a[2])
    }
    if (a.length === 2) {
      duration = duration + parseInt(a[0]) * 60
      duration = duration + parseInt(a[1])
    }
    if (a.length === 1) {
      duration = duration + parseInt(a[0])
    }

    return this._formatTime(duration)
  };

  /**
   *
   * Expects the amount of seconds the video has
   * return a formated string with the duration
   * @param {duration} duration
   */
  _formatTime (duration) {
    let seconds = parseInt(duration, 10)
    const hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds - (hours * 3600)) / 60)
    seconds = seconds - (hours * 3600) - (minutes * 60)

    if (minutes < 10 && hours !== 0) { minutes = '0' + minutes }
    if (seconds < 10) { seconds = '0' + seconds }

    let time

    if (hours === 0) {
      time = minutes + ':' + seconds
    } else {
      time = hours + ':' + minutes + ':' + seconds
    }

    return time
  }

  async _getVideoInfo (idVideo, apiKey) {
    if (idVideo) {
      try {
        const fetchUrl = getUrlFromString(`https://www.googleapis.com/youtube/v3/videos?part=contentDetails&key=${apiKey}&part=snippet&id=${idVideo}`)

        // Get the post data
        const post = await fetchJsonData(fetchUrl, { credentials: 'omit' })
        const videoName = post.items[0].snippet.title
        const videoDurationNotFormated = this._formatSecondsAsTime(post.items[0].contentDetails.duration)
        const thumbnails = post.items[0].snippet.thumbnails
        const videoThumbnailImageUrl = thumbnails[Object.keys(thumbnails)[Object.keys(thumbnails).length - 1]].url

        return [videoName, videoDurationNotFormated, videoThumbnailImageUrl]
      } catch (error) {
        if (window.newrelic) {
          window.newrelic.noticeError('[Video] Cannot retrieve video info')
        }
        return -1
      }
    } else {
      return -1
    }
  }
}

registerComponent(Video, DEFINITION.name, DEFINITION)
