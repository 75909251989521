import { fromCamelCase, encodeAttributes } from '../../../js/helpers/string'
import { LabelTemplate } from '../../components/label/c-label.template'

/**
 * The RadioButtonData contains all data needed to hydrate a RadioButton view
 *
 * @typedef {Object}          RadioButtonData
 *
 * @property {String}         id                - The element id
 * @property {String}         name              - The element/group name
 * @property {String}         value             - The form element value
 * @property {String}         text              - Option text
 * @property {Boolean}       [checked]
 * @property {Boolean}       [disabled]
 * @property {Boolean}       [unresolved]
 * @property {Boolean}       [required]         - Specifies if this input is required
 * @property {String}        [count]            - The count additional text
 * @property {String}        [additionalText]   - The additional text
 * @property {String}        [state]            - Component state, eg. validation
 * @property {String}        [extraClasses]     - Extra classes to append to the view
 * @property {Object}        [dataset]          - Extra attributes
 */

export const defaultRadioButtonData = {
  id: '',
  name: '',
  value: '',
  text: '',
  checked: false,
  disabled: false,
  unresolved: false,
  required: false,
  showExtraContentDiv: false
}

export const RadioButtonTemplate = (d) => `
<div class="c-radio-button
    ${d.disabled ? 'is-disabled' : ''}
    ${d.state ? `has-${d.state}` : ''}
    ${d.unresolved ? 'is-unresolved' : ''}
    ${d.dataset && d.dataset.illustrationSrc ? 'c-radio-button--with-illustration' : ''}
    ${d.extraClasses ? d.extraClasses : ''}">
  <input class="c-radio-button__input"
      type="radio"
      id="${d.id}"
      name="${d.name}"
      value="${d.value}"
      data-text="${d.text.replace(/"/g, '\'')}"
      ${d.disabled ? 'disabled=""' : ''}
      ${d.checked ? 'checked=""' : ''}
      ${d.required ? 'required=""' : ''}
      ${d.required ? `data-message-required="${d.requiredMessage}"` : ''}
      ${d.dataset ? Object.keys(d.dataset).map(k => `data-${fromCamelCase(k)}="${d.dataset[k]}"`).join(' ') : ''}
      ${d.attributes ? encodeAttributes(d.attributes) : ''}/>
  <label class="c-radio-button__label" for="${d.id}">
    ${d.dataset && d.dataset.illustrationSrc
      ? `<img class="c-radio-button__illustration-image" src="${d.dataset.illustrationSrc}" alt="${d.dataset.illustrationAlt}" />
      <div class="c-radio-button__element-container">`
      : ''}
    <div class="c-radio-button__element"></div>
    <div class="c-radio-button__text-wrapper ${d.highlightText ? 'c-radio-button__text-wrapper--highlighted-text' : ''}">
      ${d.highlightText
        ? LabelTemplate({
          text: d.highlightText,
          variant: 'sale',
          extraClasses: 'c-radio-button__highlight-text'
        })
        : ''}
      <div class="c-radio-button__text-content">
        ${d.dataset && d.dataset.iconName
          ? `<span class="m-icon m-icon--${d.dataset.iconName} m-icon--${d.dataset.iconSize ? d.dataset.iconSize : 'small'}"></span>`
          : ''}
        ${d.dataset && d.dataset.imageSrc ? `<img class="c-radio-button__image" src="${d.dataset.imageSrc}" alt="${d.dataset.imageAlt}" />` : ''}
        <span class="c-radio-button__text">${d.text}</span>
        ${d.count ? `<span class="c-radio-button__count">${d.count}</span>` : ''}
      </div>
    </div>
    ${d.additionalText
      ? `<div class="c-radio-button__text-wrapper c-radio-button__text-wrapper--additional-text"><span class="c-radio-button__additional-text">${d.additionalText}</span></div>`
      : ''}
      ${d.dataset && d.dataset.illustrationSrc ? '</div>' : ''}
  </label>
  ${d.showExtraContentDiv
    ? '<div class="c-radio-button__extra-content" data-c-radio-button__extra-content></div>'
    : ''}
</div>`
