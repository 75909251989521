import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'

const componentApi = 'c-number-score'

const componentQueries = {
  elementTitle: `[data-${componentApi}__title]`,
  elementLabel: `[data-${componentApi}__label]`,
  elementGrade: `[data-${componentApi}__grade]`
}

const definition = {
  name: componentApi
}

export default class NumberScore extends Component {
  /**
   * Creates a new number score behaviour.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.elementGrade = this.element.querySelector(componentQueries.elementGrade)
    this.elementTitle = this.element.querySelector(componentQueries.elementTitle)
    this.elementLabel = this.element.querySelector(componentQueries.elementLabel)

    element[this.name].getGrade = this.getGrade.bind(this)
    element[this.name].setGrade = this.setGrade.bind(this)
    element[this.name].getTitle = this.getTitle.bind(this)
    element[this.name].setTitle = this.setTitle.bind(this)
    element[this.name].getLabel = this.getLabel.bind(this)
    element[this.name].setLabel = this.setLabel.bind(this)
  }

  getGrade () {
    return this.elementGrade ? this.elementGrade.innerHTML : null
  }

  setGrade (value) {
    if (value) this.elementGrade.innerHTML = value
  }

  getTitle () {
    return this.elementTitle ? this.elementTitle.innerHTML : null
  }

  setTitle (value) {
    if (value) this.elementTitle.innerHTML = value
  }

  getLabel () {
    return this.elementLabel ? this.elementLabel.innerHTML : null
  }

  setLabel (value) {
    if (value) this.elementLabel.innerHTML = value
  }
}

registerComponent(NumberScore, definition.name, definition)
