export const da = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const de = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const en = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const nb = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const fr = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const nl = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}

export const sv = {
  accommodation: 'Accommodation',
  flight: 'Flight Ticket included',
  bus: 'Bus Ticket included',
  transfer: 'Transfer included',
  skipass: 'Ski pass included',
  carrental: 'Car rental included',
  skimaterial: 'Ski material included'
}
