import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { TextareaMessagesTemplate } from './c-textarea__messages.template'

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {

  name: 'c-textarea',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'value',
      type: 'string'
    }, {
      name: 'state',
      type: 'string',
      attr: '.has-',
      allowedValues: [
        '',
        'success',
        'warning',
        'error'
      ],
      defaultValue: ''
    }
  ]

}

/**
 * Textarea content
 *
 */
export default class Textarea extends Component {
  /**
   * Creates a new textarea behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.silentErrors = true

    this._getTextareaNode(element).addEventListener('change', (e) => {
      const newValue = e.target.value.trim()
      if (this.props.value !== newValue) this.setProp('value', newValue)
    })

    this._getTextareaNode(element).addEventListener('focus', (e) => this.events.emit('focus', e))
    this._getTextareaNode(element).addEventListener('blur', (e) => this.events.emit('blur', e))
    this._getTextareaNode(element).addEventListener('keydown', (e) => this.events.emit('keydown', e))
    this._getTextareaNode(element).addEventListener('keyup', (e) => this.events.emit('keyup', e))

    this.messagesElement = this.element.querySelector('.c-textarea__messages')
    this.messagePattern = this._getTextareaNode().getAttribute('data-message-pattern')
    this.messageRequired = this._getTextareaNode().getAttribute('data-message-required')

    this.element[this.name].validate = this.validate.bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)
  }

  getValue () {
    return this._getTextareaNode(this.element).value
  }

  async setValue (value) {
    this._getTextareaNode(this.element).value = value
    this.element.classList[value ? 'add' : 'remove']('has-value')
  }

  _getTextareaNode () {
    this.textareaNode = this.textareaNode || this.element.querySelector('.c-textarea__element')
    return this.textareaNode
  }

  enableErrors () {
    this.silentErrors = false
  }

  validate (validateOnly = false) {
    const isValid = this._getTextareaNode().validity.valid
    let messages = []
    let messageTypes = []
    if (!validateOnly && !this.silentErrors) {
      if (!isValid) {
        messages = this._getValidationMessages()
        messageTypes = this._getValidationMessageTypes()
      }
      this._styleValidity(isValid, messages)
    }
    return {
      isValid,
      errorMessages: messages,
      errorTypes: messageTypes,
      fieldName: this._getTextareaNode(this.element).name
    }
  }

  _getValidationMessages () {
    const messages = []
    const validity = this._getTextareaNode().validity
    if (validity.patternMismatch && this.messagePattern) {
      messages.push(this.messagePattern)
    }
    if (validity.valueMissing && this.messageRequired) {
      messages.push(this.messageRequired)
    }
    return messages
  }

  _getValidationMessageTypes () {
    const messageTypes = []
    const validity = this._getTextboxNode().validity
    if (validity.patternMismatch && this.messagePattern) {
      messageTypes.push('PatternMismatch')
    }
    if (validity.valueMissing && this.messageRequired) {
      messageTypes.push('ValueMissing')
    }
    return messageTypes
  }

  _styleValidity (isValid, messages) {
    if (!isValid) {
      this.element.classList.add('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = TextareaMessagesTemplate(messages)
      }
    } else {
      this.element.classList.remove('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ''
      }
    }
  }
}

registerComponent(Textarea, definition.name, definition)
