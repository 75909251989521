/**
 * The PackageItemData contains all data needed to hydrate a PackageItem view
 *
 * @typedef {Object} PackageItemData
 *
 * @property {Collection}          collection        - The package item collection
 * @property {String}              variant           - The package item variant
 * @property {String}              extraClasses      - The package item extra classes
 * @property {ImageData}           image             - The package item image
 * @property {String}              starRating        - The package item rating in stars (0-5)
 * @property {NumberScoreData}     userRating        - The package item user rating
 * @property {String}              titleTag          - The package item tag type for the title
 * @property {String}              name              - The package item name
 * @property {BreadcrumbsData}     breadcrumbs       - The package item breadcrumbs
 * @property {PackageBlockData}    packages          - The package item package block
 * @property {PriceData}           price             - The package item price
 * @property {LinkData}            [packageItemLink] - The package item link
 */

/**
 * The LinkData contains all data needed to hydrate a Link view
 *
 * @typedef {Object} LinkData
 *
 * @property {String}              href              - The url of the link
 * @property {String}              target            - The target of the link
 * @property {String}              title             - The title of the link
 */
import { ImgTemplate } from '../../components/img/c-img.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { RatingTemplate } from '../../components/rating/c-rating.template'
import { BreadcrumbsTemplate } from '../../components/breadcrumbs/c-breadcrumbs.template'
import { PackageBlockTemplate } from '../../components/package-block/c-package-block.template'
import { PriceTemplate } from '../../components/price/c-price.template'
import { NumberScoreTemplate } from '../../components/number-score/c-number-score.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { getToken, getTokenClass } from '../../../js/helpers/tokens'
import { getCollectionIcon } from '../../../js/helpers/collection-mapper'

// Import tokens
const tokensShared = require('./data/c-package-item__tokens.json')['c-package-item']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/package-item/data/c-package-item__tokens.json`)[ // eslint-disable-line
      'c-package-item'
    ]
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

// Import cross-component shared tokens
const tokensCollections = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/data/ds-tokens-shared.json`)['collections']; // eslint-disable-line
  } catch (error) {
    return {}
  }
})()

export const defaultPackageItemData = {
  collection: '',
  variant: 'default',
  extraClasses: '',
  starRating: '',
  titleTag: '',
  name: '',
  track: '',
  showCtaButton: false,
  ctaButtonText: ''
}

export const PackageItemTemplate = d => {
  d = { ...defaultPackageItemData, ...d }
  const collectionId = d.collection ? d.collection.id : ''
  const collectionToken = getToken('collection', collectionId, tokensCollections)
  const showCtaButton = d.showCtaButton === true || d.showCtaButton === 'true'

  return `
<div class="c-package-item
    ${d.collection ? 'c-package-item--collection' : ''}
    ${d.collection && collectionToken ? `c-package-item--collection${collectionToken.modifier}` : ''}
    ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''}
    ${d.extraClasses || ''}">
  <div class="c-package-item__img">
    ${d.variant === 'default' ? `${ImgTemplate({ ...d.image, ratio: '3:2' })}` : ''}
    ${d.variant === 'listed' ? `${ImgTemplate({ ...d.image, ratio: '1:1' })}` : ''}
    ${
      d.collection
        ? `${LabelTemplate({
            text: d.collection.text,
            collection: d.collection.id,
            icon: getCollectionIcon(d.collection)
          })}`
        : ''
    }
  </div>

  <div class="c-package-item__body">
    <div class="c-package-item__body-heading">
       ${RatingTemplate({ value: d.starRating })}
       ${d.userRating ? NumberScoreTemplate(d.userRating) : ''}
       
       <${d.titleTag ? d.titleTag : 'h2'} class="c-package-item__title ">${d.name}
       </${d.titleTag ? d.titleTag : 'h2'}>

       ${BreadcrumbsTemplate(d.breadcrumbs)}
    </div>

    <div class="c-package-item__body-footer">
      <div class="c-package-item__package-row">
        ${PackageBlockTemplate({
          includedServices: Array.isArray(d.packages.includedServices)
            ? d.packages.includedServices
            : Object.keys(d.packages.includedServices),
          flightRoutes: d.flightRoutes
        })}
      </div>
      <div class="c-package-item__price">
        ${PriceTemplate(d.price)}
      </div>
    </div>
    ${
      showCtaButton
        ? `<div class="c-package-item__cta-button">
          ${BtnTemplate({
            ...defaultButtonData,
            ...{
              block: true,
              variant: 'flow',
              text: d.ctaButtonText,
              track: d.track ? d.track + '.CTABtn' : '',
              link: {
                href: d.packageItemLink.href,
                target: d.packageItemLink.target,
                title: d.packageItemLink.title
              }
            }
          })}        
          ${BtnTemplate({
            ...defaultButtonData,
            ...{
              block: true,
              variant: 'flow',
              text: d.ctaButtonText,
              track: d.track ? d.track + '.CTABtn' : '',
              link: {
                href: d.packageItemLink.href,
                target: '_blank',
                title: d.packageItemLink.title
              }
            }
          })}        
        </div>`
        : ''
    }  
  </div>

  ${
    d.packageItemLink && d.packageItemLink.href
      ? `<a href="${d.packageItemLink.href}"
    ${d.packageItemLink.title ? `title="${d.packageItemLink.title}"` : ''}
    ${d.packageItemLink.target ? `target="${d.packageItemLink.target}"` : ''}
    class="c-package-item__link"></a>

    <a href="${d.packageItemLink.href}"
    ${d.packageItemLink.title ? `title="${d.packageItemLink.title}"` : ''}
    target="_blank"
    class="c-package-item__link"></a>`
      : ''
  }
</div>`
}
