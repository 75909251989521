import { COOKIE_NAME as PARTICIPANTS_COOKIE_NAME } from './participants-data-model'

export const COMPONENT_API = 'c-participants-selector'

export const PROPS_ATTRIBUTES = {
  allocation: `data-${COMPONENT_API}__allocation`,
  participants: `data-${COMPONENT_API}__participants`,
  cookieEnabled: `data-${COMPONENT_API}__cookie-enabled`,
  saveCookie: `data-${COMPONENT_API}__save-cookie`,
  cookieName: `data-${COMPONENT_API}__cookie-name`,
  maxRooms: `data-${COMPONENT_API}__max-rooms`,
  participantsDistribution: `data-${COMPONENT_API}__participants-distribution`,
  humanizedTextPattern: `data-${COMPONENT_API}__humanizedTextPattern`
}

export const COMPONENT_QUERIES = {
  addRoomClass: `${COMPONENT_API}__add-room`,
  ageProfiles: `[data-${COMPONENT_API}__age-profiles]`,
  allocationOptions: `[data-${COMPONENT_API}__allocation-options]`,
  dateSelector: '[data-js-component="c-date-selector"],[data-date-selector]',
  dynamicContent: `[data-${COMPONENT_API}__dynamic-content]`,
  cancel: `[data-${COMPONENT_API}__action="cancel"]`,
  childrenDates: `${COMPONENT_API}__children-dates`,
  childrenDatesRequired: `${COMPONENT_API}__children-dates-required`,
  cookieName: `data-${COMPONENT_API}__cookie-name`,
  floatingBox: `[data-${COMPONENT_API}__floating-box]`,
  maxRoomAttr: `data-${COMPONENT_API}__max-room`,
  profile: `data-${COMPONENT_API}__profile-id`,
  removeRoomClass: `${COMPONENT_API}__remove-room`,
  room: `data-${COMPONENT_API}__room-id`,
  roomTitleClass: `${COMPONENT_API}__room-title`,
  roomTitleWrapperClass: `${COMPONENT_API}__room-title-wrapper`,
  submit: `[data-${COMPONENT_API}__action="submit"]`,
  textBox: `[data-${COMPONENT_API}__textbox]`,
  roomsWrapper: `${COMPONENT_API}__room-wrapper`,
  multiRoom: `${COMPONENT_API}__multi-room`
}

export const TEXT_PATTERNS = {
  participants: '{PARTICIPANTS}',
  rooms: '{NUM_ROOMS}',
  maxRooms: '{MAX_ROOMS}'
}

/**
 * ⚠ Age profile names are different on several places 👏, fixed here
 */
export const CORRECT_AGE_PROFILE_NAMES = {
  Adult: 'adults',
  Child: 'children',
  Junior: 'babies'
}

export const COMPONENT_DEFINITION = {
  name: 'c-participants-selector',
  props: [
    {
      name: 'allocation',
      type: 'string',
      attr: PROPS_ATTRIBUTES.allocation,
      defaultValue: ''
    }, {
      name: 'cookieEnabled',
      type: 'boolean',
      attr: PROPS_ATTRIBUTES.cookieEnabled,
      defaultValue: true
    }, {
      name: 'saveCookie',
      type: 'boolean',
      attr: PROPS_ATTRIBUTES.saveCookie,
      defaultValue: false
    }, {
      name: 'cookieName',
      type: 'string',
      attr: PROPS_ATTRIBUTES.cookieName,
      defaultValue: PARTICIPANTS_COOKIE_NAME
    }, {
      name: 'maxRooms',
      type: 'number',
      attr: PROPS_ATTRIBUTES.maxRooms,
      defaultValue: 1
    }, {
      name: 'participants',
      type: 'array',
      attr: PROPS_ATTRIBUTES.participants,
      defaultValue: []
    }, {
      name: 'participantsDistribution',
      type: 'array',
      attr: PROPS_ATTRIBUTES.participantsDistribution,
      defaultValue: []
    }, {
      name: 'humanizedTextPattern',
      type: 'string',
      attr: PROPS_ATTRIBUTES.humanizedTextPattern,
      defaultValue: ''
    }, {
      name: 'ageProfiles',
      type: 'array',
      defaultValue: []
    }
  ]
}
