/**
 * The TooltipData contains all data needed to hydrate a Tooltip view
 *
 * @typedef {Object}          TooltipData
 *
 * @property {String}           displayOn                     - direction where to show the tooltip, calues supported: left, right, top, bottom
 * @property {String}           displayOnTopFrom              - Breakpoint where should start displaying on top, values supported: xs, sml, md
 * @property {String}           displayOnRightFrom            - Breakpoint where should start displaying on right, values supported: xs, sml, md
 * @property {String}           displayOnBottomFrom           - Breakpoint where should start displaying on bottom, values supported: xs, sml, md
 * @property {String}           displayOnLeftFrom             - Breakpoint where should start displaying on left, values supported: xs, sml, md
 * @property {String}           extraClasses                  - CSS extra classes
 * @property {String}           html                          - HTML snippet
 * @property {String}           text                          - Tooltip contained in te text
 */

export const defaultTooltipData = {
  displayOn: 'right',
  displayOnTopFrom: '',
  displayOnRightFrom: '',
  displayOnBottomFrom: '',
  displayOnLeftFrom: '',
  extraClasses: '',
  html: '',
  text: ''
}

export const TooltipTemplate = (d) => {
  d = { ...defaultTooltipData, ...d }
  return `
    <div class="c-tooltip ${d.extraClasses}
      c-tooltip--${d.displayOn}
      ${d.displayOnTopFrom ? `c-tooltip--top-from@${d.displayOnTopFrom}` : ''}
      ${d.displayOnRightFrom ? `c-tooltip--right-from@${d.displayOnRightFrom}` : ''}
      ${d.displayOnBottomFrom ? `c-tooltip--bottom-from@${d.displayOnBottomFrom}` : ''}
      ${d.displayOnLeftFrom ? `c-tooltip--left-from@${d.displayOnLeftFrom}` : ''}"
      data-js-component="c-tooltip"
      data-c-tooltip__text="${d.text}">
      ${d.html}
    </div>
  `
}
