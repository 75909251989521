import { getTokenClass } from '../../../js/helpers/tokens'
import { defaultModalData } from './c-modal-v2.template'

// Import tokens
const tokensShared = require('./data/c-modal-v2__tokens.json')['c-modal-v2']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal-v2/data/c-modal-v2__tokens.json`)['c-modal-v2']; // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const ModalV2BodyTemplate = d => {
  d = { ...defaultModalData, ...d }
  return `
<div
  class="c-modal-v2__body ${d.async ? 'c-modal-v2__body--async' : ''} m-body 
    ${getTokenClass('body__size', 'default', tokens)} 
    ${getTokenClass(
      'space-stack-children',
      'default',
      tokens
    )}" data-c-modal-v2-body> 
    <div class="c-modal-v2__body-content">
      ${d.bodyContent}
    </div>
 </div>
`
}
