import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import * as utilsHelper from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'

const definition = {
  name: 'c-tooltip'
}

const settings = {
  resizeDelay: 200
}

const classNames = {
  opened: 'in',
  right: `${definition.name}--right`,
  left: `${definition.name}--left`,
  top: `${definition.name}--top`,
  bottom: `${definition.name}--bottom`
}

export default class Tooltip extends Component {
  constructor (element) {
    super(element, definition.name)
    if (this._isIE()) {
      this.element.classList.add('is-ie11')
      return
    }
    this.initialCssClasses = this.element.className
    this._attachEvents()
    this._updateDirection()
  }

  _attachEvents () {
    if (this._isTouchDevice()) {
      window.addEventListener('touchend', this._touchDevicesHandler.bind(this))
    } else {
      this.element.addEventListener('mouseenter', this._openHandler.bind(this))
      this.element.addEventListener('mouseleave', this._closeHandler.bind(this))
    }

    this._events = [[window, { resize: utilsHelper.debounce(() => this._updateDirection(), settings.resizeDelay) }]]
    domEventsHelper.attachEvents(this._events, definition.name)
  }

  _openHandler () {
    this.element.classList.add(classNames.opened)
  }

  _touchDevicesHandler (event) {
    const isTouched = this.element.contains(event.target)
    if (isTouched) {
      this._openHandler()
    } else {
      this._closeHandler()
    }
  }

  _closeHandler () {
    this.element.classList.remove(classNames.opened)
  }

  _isTouchDevice () {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints
  }

  _updateDirection () {
    // reset to initial direction
    this.element.className = this.initialCssClasses

    // tooltip and window measurements
    const tooltipMaxWidth = parseInt(document.defaultView.getComputedStyle(this.element, ':before').maxWidth)
    const tooltipOffset = parseInt(document.defaultView.getComputedStyle(this.element, ':before').getPropertyValue('--tooltip-arrow-offset'))
    const windowWidth = window.innerWidth
    const leftPositionTriggerTooltip = this.element.getBoundingClientRect().left
    const widthTriggerTooltip = this.element.getBoundingClientRect().width

    // conditionals
    const rightTooltipNotFitRightSide = windowWidth - (leftPositionTriggerTooltip + widthTriggerTooltip) < tooltipMaxWidth + tooltipOffset
    const topTooltipFitRightSide = windowWidth - leftPositionTriggerTooltip - (widthTriggerTooltip / 2) > tooltipMaxWidth / 2
    const leftTooltipNotFitLeftSide = leftPositionTriggerTooltip - tooltipMaxWidth - tooltipOffset < 0
    const topTooltipFitLeftSide = leftPositionTriggerTooltip + (widthTriggerTooltip / 2) - (tooltipMaxWidth / 2) > 0

    if (this.element.classList.contains(classNames.right) && rightTooltipNotFitRightSide) {
      this.element.classList.remove(classNames.right)
      if (topTooltipFitRightSide) {
        this.element.classList.add(classNames.top)
      } else {
        this.element.classList.add(classNames.left)
      }
    } else if (this.element.classList.contains(classNames.left) && leftTooltipNotFitLeftSide) {
      this.element.classList.remove(classNames.left)
      if (topTooltipFitLeftSide) {
        this.element.classList.add(classNames.top)
      } else {
        this.element.classList.add(classNames.right)
      }
    }
  }

  _isIE () {
    const ua = window.navigator.userAgent // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE ') // IE 10 or older
    const trident = ua.indexOf('Trident/') // IE 11

    return (msie > 0 || trident > 0)
  }
}

registerComponent(Tooltip, definition.name, definition)
