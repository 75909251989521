import { getTokenClass } from '../../../js/helpers/tokens'
import { defaultModalData } from './c-modal-v2.template'
import { BtnTemplate } from '../btn/c-btn.template'

// Import tokens
const tokensShared = require('./data/c-modal-v2__tokens.json')['c-modal-v2']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal-v2/data/c-modal-v2__tokens.json`)['c-modal-v2'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const ModalV2HeaderTemplate = (d) => {
  d = { ...defaultModalData, ...d }

  if ((d.id && d.closable) || (d.title && d.title !== '')) {
    return `
      <div class="c-modal-v2__header ${d.async ? 'c-modal-v2__header--async' : ''}
          ${d.async ? 'c-modal-v2--async' : ''} 
          ${d.title ? 'has-title' : ''}${d.extraClasses}" data-c-modal-v2-header>
          ${d.preHeaderContent ? `<div class="c-modal-v2__pre-header">${d.preHeaderContent}</div>` : ''}
          <div class="c-modal-v2__header-title">
          ${d.logoUrl && d.id && d.brandName ? `<img class="c-modal-v2__header-logo-img" src="${d.logoUrl}" alt="${d.brandName}">` : ''}
          <${d.headerTag}
            class="c-modal-v2__header-text m-heading ${getTokenClass('header__size', 'default', tokens)}">
              ${d.title}
          </${d.headerTag}>
          </div>
          ${d.id && d.closable
            ? BtnTemplate({
              type: 'button',
              icon: 'cross',
              iconPosition: 'left',
              variant: 'icon-only',
              extraClasses: 'c-modal-v2__close',
              attributes: { 'data-c-modal-v2__action': 'close', 'data-c-modal-v2__id': d.id }
            })
            : ''}
       </div>
      `
  }

  return ''
}
