import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { smooth } from '../../../js/document/scroll'
import { tabsEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'

const definition = {
  name: 'c-tabs',
  props: [
    {
      name: 'currentTab',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'scrollOnChange',
      type: 'boolean',
      attr: 'data-c-tabs__scroll-on-change',
      defaultValue: false
    }
  ],
  actionElements: true
}

const classes = {
  openTab: 'is-open',
  tabsContent: `${definition.name}__content`
}

const attributes = {
  tabToOpenId: `data-${definition.name}__tab-id`,
  fallBackTabToOpenId: `data-${definition.name}__fb-tab-id`,
  anchorable: `data-${definition.name}__anchorable`,
  tabContent: `data-${definition.name}__tab-content`
}

const scrollOffset = 80

const attr = {
  track: 'data-track'
}

const componentsApi = {
  button: 'c-btn',
  collapse: 'c-collapse'
}

/**
 * Tabs content
 *
 */
export default class Tabs extends Component {
  /**
   * Creates a new dropdown behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.element = element

    element[this.name].showTab = this.showTab.bind(this)
    element[this.name].setCurrentTab = this.setCurrentTab.bind(this)
    element[this.name].setCurrentHashHistory = this.setCurrentHashHistory.bind(this)

    this.isAnchorable = this.element.hasAttribute(attributes.anchorable)

    this._getActionElements()
    this._getContentElements()

    // Update participants data and textBox value each valuable change
    this.events.on(tabsEvents.PROP_CHANGED, ({ name, value, oldValue }) => {
      if (name === 'currentTab') {
        const innerCollapseComponents = this.element.querySelector(`#${value}`)
          .querySelectorAll('[data-js-component="' + componentsApi.collapse + '"]')
        innerCollapseComponents.forEach(collapseElement => {
          const componentApi = collapseElement[componentsApi.collapse]
          if (componentApi) componentApi.update()
        })
        this.events.emit(tabsEvents.TAB_CHANGED)
      }
    })

    this.events.on(tabsEvents.CLICKED, (ev) => {
      this.events.emit(tabsEvents.TAB_CHANGED, ev)
    })

    this.eventData = this._getEventData()
    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    if (this.isAnchorable) {
      const initialHash = this.getIdFromHash(window.location.hash)

      if (initialHash !== null || initialHash !== '') {
        this.setCurrentTabByHash(initialHash)
      }

      window.addEventListener('popstate', (e) => this.handleHashChange(e))
    }

    this._sentEventLoaded()
  }

  getIdFromHash (hash) {
    return (hash === null || hash === '') ? hash : hash.substring(1)
  }

  handleHashChange () {
    const hash = this.getIdFromHash(window.location.hash)

    if (hash === null || hash === '') {
      this.setCurrentTabProp(this.element.querySelectorAll(`[${attributes.tabContent}]`)[0].id)
    } else {
      this.setCurrentTabByHash(hash)
    }
  }

  setCurrentTabByHash (hash) {
    if (hash) {
      try {
        const targetTab = this.element.querySelector(`#${hash}`)?.closest(`[${attributes.tabContent}]`)
        if (targetTab) {
          this.setCurrentTabProp(targetTab.id)
        }
      } catch (e) {
        if (window.newrelic) {
          window.newrelic.noticeError('[tabs] Error setting tab by hash, hash used: ' + hash)
        }
      }
    }
  }

  /**
   * Function that sents an event when the tab component loads with the default opened tab
   *
   */
  _sentEventLoaded () {
    setTimeout(() => {
      const elementEventData = this.eventData
      const defaultButton = this.element.querySelector(`[${attributes.tabToOpenId}="${this.getCurrentTab()}"]`)

      if (defaultButton && defaultButton[componentsApi.button]) {
        const defaultButtonData = defaultButton[componentsApi.button].getDataAttributes()

        this.events.emit(tabsEvents.LOADED, {
          elementEventData,
          buttonData: defaultButtonData
        })
      }
    }, 0)
  }

  /**
   * Function called to retrieve dataset information of the component
   *
   */
  _getEventData () {
    const eventData = {}
    if (this.element) {
      eventData.attr = this.element.dataset
    }
    return eventData
  }

  /**
   * Function called by the action elements action. Gets the clicked attribute and calls the set function
   *
   * @param {HTMLElement} el - The HTML element.
   */
  showTab (el) {
    let newTab = el.getAttribute(attributes.tabToOpenId)
    const elementEventData = this.eventData

    this.setCurrentHashHistory(newTab)

    if (el[componentsApi.button]) {
      this.events.emit(tabsEvents.CLICKED, {
        elementEventData,
        buttonData: el[componentsApi.button].getDataAttributes()
      })
    }

    if (newTab) {
      if (!document.getElementById(newTab)) {
        newTab = el.getAttribute(attributes.fallBackTabToOpenId)
      }

      if (newTab) { this.setCurrentTabProp(newTab) }
    }
  }

  /**
   * Check if the new currentTab is a valid value for the prop
   *
   * @param {String} currentTab - The new current tab id.
   */
  checkCurrentTab (currentTab) {
    return !!this.element.querySelector(`#${currentTab}`)
  }

  setCurrentTabProp (tabId) {
    this.setProp('currentTab', tabId)
  }

  setCurrentHashHistory (tabId) {
    if (this.isAnchorable) {
      if (tabId !== window.location.hash) {
        window.history.pushState(null, null, `#${tabId}`)
      }
    }
  }

  /**
   * Sets the currentTab prop
   *
   * @param {String} currentTab - The new current tab id.
   */
  setCurrentTab (currentTab) {
    this._contentElements.forEach(e => e.classList.toggle(classes.openTab, e.id === currentTab))
    this._actionElements.forEach(e => {
      const isOpen = e.getAttribute(attributes.tabToOpenId) === currentTab
      e.parentElement.classList.toggle(classes.openTab, isOpen)
    })
    if (this.getProp('scrollOnChange')) {
      const currentTabElement = this._contentElements.find(e => e.id === currentTab)
      const scrollingElement = document.scrollingElement || document.documentElement
      smooth(scrollingElement, 0, currentTabElement.offsetTop - scrollOffset)
    }
  }

  /**
   * Get the currentTab prop
   */
  getCurrentTab () {
    const openedTabs = this._getContentElements().filter(e => e.classList.contains(classes.openTab))
    return openedTabs.length ? openedTabs[0].id : ''
  }

  /**
   * Search for Content elements on element.
   *
   * @returns {HTMLElement[]} Array of action elements.
   */
  _getContentElements () {
    this._contentElements = this._contentElements || [...this.element.querySelectorAll(`#${this.element.id} > .${classes.tabsContent}`)]
    return this._contentElements
  }

  /**
   * Search for ActionElements on document.
   *
   * @returns {HTMLElement[]} Array of action elements.
   */
  _getActionElements () {
    this._actionElements = this._actionElements || (this.element.id
      ? [...document.querySelectorAll(`[data-${definition.name}__id="${this.element.id}"]`)]
      : [])
    return this._actionElements
  }
}

registerComponent(Tabs, definition.name, definition)
