import domEventsHelper from '../../../js/document/dom-events-helper'
import Component from '../../../js/core/component/component'
import { defaultNumberStepperData, NumberStepperTemplate } from '../../components/number-stepper/c-number-stepper.template'
import ParticipantsDataModel from './participants-data-model'
import { ChoiceListTemplate, defaultChoiceListData } from '../choice-list/c-choice-list.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { DateSelectorTemplate } from '../date-selector/c-date-selector.template'
import { debounce } from '../../../js/utils'
import { registerComponent } from '../../../js/core/component/component-directory'
import { filterDatesInRange } from '../../../js/helpers/dates'
import { flatten } from '../../../js/helpers/array'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { BtnTemplate } from '../btn/c-btn.template'
import { queueFilterEvent } from '../../../js/helpers/queue-filter-event'
import {
  COMPONENT_API,
  COMPONENT_QUERIES,
  TEXT_PATTERNS,
  COMPONENT_DEFINITION, CORRECT_AGE_PROFILE_NAMES
} from './config'

const componentLocales = register(`window.sundio.i18n.${language}.participantsSelector`)

// Ensure other child component APIs are loaded on time
require('../../components/textbox/main')
require('../../components/floating-box/main')
require('../../components/number-stepper/main')

const defaults = {}

/**
 * ParticipantsSelector content
 *
 */
export default class ParticipantsSelector extends Component {
  /**
   * Creates a new ParticipantsSelector component
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   * @param {Object} [options={}] - Options object
   */
  constructor (element, options = {}) {
    super(element, COMPONENT_DEFINITION.name)
    const { cookieEnabled, saveCookie, cookieName, maxRooms, ...dataProps } = this.props
    this.participantsData = new ParticipantsDataModel(dataProps, { cookieEnabled, saveCookie, cookieName })

    this.options = {
      ...defaults,
      ageProfiles: this._getAgeProfilesFromDatalist(),
      allocationOptions: this._getAllocationOptionsFromDatalist(),
      ...options
    }
    this.options.allocationAllowedValues = this.options.allocationOptions.map(({ value }) => value)

    // Get the local strings
    this.locales = this._getLocales()

    // Debounce function for _getDates
    this.debouncedGetDates = debounce(() => this._getDates(), 100)

    // Run the queries finding for elements
    this.textBox = this.element.querySelector(COMPONENT_QUERIES.textBox)

    this.textBoxElement = this.textBox.querySelector('input')
    this.floatingBox = this.element.querySelector(COMPONENT_QUERIES.floatingBox)
    this.dynamicContent = this.element.querySelector(COMPONENT_QUERIES.dynamicContent)
    this.cancel = this.element.querySelector(COMPONENT_QUERIES.cancel)
    this.submit = this.element.querySelector(COMPONENT_QUERIES.submit)

    // Access to available elements API
    this.textBoxApi = this.textBox['c-textbox']
    this.floatingBoxApi = this.floatingBox['c-floating-box']

    // Bind events
    this.textBoxElement.addEventListener('click', this._onFocus.bind(this))
    this.textBox.querySelector('.c-textbox__icon').addEventListener('click', this._onFocus.bind(this))
    // Bind DOM events
    this._buildEvents()
    domEventsHelper.attachEvents(this._domEvents, COMPONENT_API)

    // Initialize textBox
    this._setTextBoxValue()

    // Enrich component public API
    this.element[COMPONENT_API].participantsData = this.participantsData
    this.element[COMPONENT_API].isFlightOnly = false
    this.element[COMPONENT_API].remove = this.remove.bind(this)
    this.element[COMPONENT_API].updateAllocationOptions = this.updateAllocationOptions.bind(this)
    this.element[COMPONENT_API].updateParticipantsData = this.updateParticipantsData.bind(this)
    this.element[COMPONENT_API].getDefaultAgeProfileBirthdate = this.getDefaultAgeProfileBirthdate.bind(this)
    this.element[COMPONENT_API].getParticipantsData = () => this.participantsData.getParticipantsGroupedByRoom()
    this.element[COMPONENT_API].getAllocation = () => this.participantsData.getAttribute('allocation')
    this.element[COMPONENT_API].getParticipantsByAgeProfiles = this.getParticipantsByAgeProfiles.bind(this)
    this.element[COMPONENT_API].getParticipantsByAgeProfilesAndRooms = this.getParticipantsByAgeProfilesAndRooms.bind(this)
    this.element[COMPONENT_API].getBirthDatesFromPartyCompositions = this.getBirthDatesFromPartyCompositions.bind(this)
    this.element[COMPONENT_API].open = this._onFocus.bind(this)
    this.element[COMPONENT_API].updateParticipantsFilter = this._updateParticipantsFilter.bind(this)

    // Update participants data and textBox value each valuable change
    this.events.on('propChanged', ({ name, value, oldValue }) => {
      if (['participants', 'allocation', 'participantsDistribution'].includes(name)) {
        this.participantsData.setAttribute(name, value)
      }
      if (['participants', 'participantsDistribution'].includes(name)) { this._setTextBoxValue() }
    })

    // In case there's no data, use a fallback to avoid JS exploding
    if (!this.participantsData.getAttribute('participants').length) {
      this._provideFallbackValues()
    }
  }

  /**
   * Return a locale strings object
   */
  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) { }

    return { ...componentLocales, ...(customLocaleData || {}) }
  }

  /**
   * Removes the component instance
   */
  remove () {
    delete this.element[COMPONENT_API]
  }

  toggleFloatingBox () {
    if (this.floatingBoxApi.getProp('opened')) {
      this._onCancel()
    } else {
      this._onFocus()
    }
  }

  _openFloatingBox () {
    this.textBoxElement.classList.add('is-focused')
    this.floatingBoxApi.setProp('opened', true)
    this._floatingBoxEvents = [
      [document, { mousedown: (ev) => this._onDocumentClick(ev) }]
    ]
    domEventsHelper.attachEvents(this._floatingBoxEvents, COMPONENT_API)
  }

  _closeFloatingBox () {
    if (this.floatingBoxApi.getProp('opened')) {
      this.tempSelection = null
      this.floatingBoxApi.setProp('opened', false).then(() => {
        this.textBoxElement.classList.remove('is-focused')
        domEventsHelper.detachEvents(this._floatingBoxEvents, COMPONENT_API)
        this._removeGeneralListeners()
        this._removeNumberStepperListeners()
        this._removeChoiceListListener()
      })
    }
  }

  /**
   * Updates the available allocation options
   */
  updateAllocationOptions (allocationOptions) {
    this.options.allocationOptions = allocationOptions
    this.options.allocationAllowedValues = allocationOptions.map(({ value }) => value)
    return this
  }

  /**
   * Updates the participantsData model
   */
  updateParticipantsData (participants, allocation, participantsDistribution) {
    this.participantsData.setAttribute('participants', participants, { silent: true })
    this.participantsData.setAttribute('allocation', allocation, { silent: true })
    this.participantsData.setAttribute('participantsDistribution', participantsDistribution, { silent: true })
    this._setTextBoxValue()
  }

  /**
   * Provides fallback values if there's no participants
   */
  _provideFallbackValues () {
    const defaultAgeProfile = this.options.ageProfiles.find(p => p.defaultProfile)
    this.setProps({
      participants: Array(2).fill(defaultAgeProfile.defaultBirthdate),
      allocation: this.options.allocationOptions.length
        ? this.options.allocationOptions[0].value
        : '',
      participantsDistribution: ['1|2']
    })
  }

  getDefaultAgeProfileBirthdate () {
    const defaultAgeProfile = this.options.ageProfiles.find(ageProfile => (ageProfile.defaultProfile === 'true' || ageProfile.defaultProfile === true))
    return defaultAgeProfile && defaultAgeProfile.defaultBirthdate
      ? defaultAgeProfile.defaultBirthdate
      : undefined
  }

  getParticipantsByAgeProfiles (participants = this.participantsData.getAttribute('participants')) {
    return this.options.ageProfiles.reduce((result, ageProfile) => {
      result[ageProfile.id] = filterDatesInRange(participants, ageProfile.minBirthdate, ageProfile.maxBirthdate)
      return result
    }, {})
  }

  getParticipantsByAgeProfilesAndRooms () {
    return this.participantsData.getParticipantsGroupedByRoom()
      .map(roomParticipants => this.getParticipantsByAgeProfiles(roomParticipants))
  }

  getBirthDatesFromPartyCompositions (partyCompositions = []) {
    if (!partyCompositions.length) return undefined
    const currentDistributedParticipants = this.getParticipantsByAgeProfilesAndRooms()
    const roomsAmountRequested = partyCompositions.length
    const roomsAmountKnown = currentDistributedParticipants.length

    // In case roomsAmountKnown == 1 && more roomsAmountRequested assume we are PP
    if (roomsAmountKnown === 1 && roomsAmountRequested >= 1) {
      const result = partyCompositions.map(partyComposition => {
        const birthDates = []
        Object.entries(partyComposition).forEach(([ageProfile, amount]) => {
          while (amount) {
            birthDates.push(currentDistributedParticipants[0][ageProfile].shift())
            amount--
          }
        })
        return birthDates
      })
      const resultParticipants = flatten(result).sort()
      const knownParticipants = flatten(this.participantsData.getParticipantsGroupedByRoom()).sort()
      const resultMatches = JSON.stringify(resultParticipants) === JSON.stringify(knownParticipants)
      return resultMatches ? result : null
    } else if (roomsAmountKnown === roomsAmountRequested) {
      // TODO: Return current participants if matches totals
    } else {
      // TODO: Handle error
    }

    // In case currentDistributedParticipants.length && roomsRequested are the same, assume there's one room, or DP same rooms

    // (this.props.maxRooms <= 1)
  }

  /*
  * -----------------------------------------------------
  * DOM RELATED METHODS
  * -----------------------------------------------------
  * */

  _updateDynamicContent () {
    this.roomLastIndex = 0
    const newContent = elementFromString(this._getDynamicContentHTML())
    Component.initDocumentComponentsFromAPI(newContent)
    flush(this.dynamicContent)
    this.dynamicContent.appendChild(newContent)
    this.dateSelectors = Array.from(this.dynamicContent.querySelectorAll(COMPONENT_QUERIES.dateSelector))
    this._addGeneralListeners()
    this._addAddRoomStepperListener(newContent)
    this._addNumberStepperListeners(newContent)
    this._addDateSelectorListener(this.dateSelectors)
    this._addChoiceListListener(newContent)
    this._updateRoomInfo()
    return this
  }

  _getAgeProfilesFromDatalist () {
    const ageProfilesDatalist = this.element.querySelector(COMPONENT_QUERIES.ageProfiles)
    return ageProfilesDatalist
      ? [...ageProfilesDatalist.children].map(e => ({
          ...e.dataset,
          id: CORRECT_AGE_PROFILE_NAMES[e.dataset.id]
        }))
      : []
  }

  _getAllocationOptionsFromDatalist () {
    const allocationOptionsDatalist = this.element.querySelector(COMPONENT_QUERIES.allocationOptions)
    return allocationOptionsDatalist
      ? [...allocationOptionsDatalist.children].map(e => ({ ...e.dataset }))
      : []
  }

  _getDates () {
    this.dateSelectors = this.dateSelectors.filter((ds) => ds.isConnected)
    const areDatesValid = this.dateSelectors.map((dateSelector) => (dateSelector['c-date-selector'] || { valid: true }))
      .every((validDate) => validDate.valid)
    this.submit.disabled = !areDatesValid
    if (areDatesValid) {
      const distributedDates = this.dateSelectors
        .map((ds) => [ds.closest(`[${COMPONENT_QUERIES.room}]`).getAttribute(COMPONENT_QUERIES.room), ds['c-date-selector']
          ? ds['c-date-selector'].getShownDate()
          : ds.value])
        .reduce((array, [roomId, date]) => {
          array[roomId] = [...(array[roomId] || []), date]
          return array
        }, [])
      this.tempSelection.setParticipantAndDistribution(distributedDates)
    }
  }

  _updateRoomInfo () {
    Array.from(this.dynamicContent.querySelectorAll(`[${COMPONENT_QUERIES.room}]`))
      .forEach((roomElement, index) => {
        if (index !== 0) {
          roomElement.setAttribute(COMPONENT_QUERIES.room, index)
          roomElement.querySelector(`.${COMPONENT_QUERIES.roomTitleClass}`).childNodes[0].textContent = `${this.locales.room} ${index + 1}`
        }
      })

    this.addRoomStepperApi && this.addRoomStepperApi.setProp('value', this.roomLastIndex + 1, { silent: true })
  }

  /**
   * Set the text box value of the participants selector.
   * Depending if we are in single room case (fine grain detail of participants) or multi-room case
   * (in that case a coarse grain detail of participants and rooms is displayed).
   *
   * Parsing of the age profile section of the JSON is according to the expected following structure:
   * {
   *   "id": "adults",
   *   "text": {
   *     "singular": "Adult",
   *     "plural": "Adults"
   *   },
   *   "extraText": "From 17 years old",
   *   "resultsText": "{N} adult||||{N} adults",
   *   "defaultBirthdate": "1988-03-20",
   *   "maxBirthdate": "2002-10-01",
   *   "maxPeople": "10",
   *   "defaultProfile": true
   * }
   */
  _setTextBoxValue () {
    const participantsNumber = this.participantsData.getAttribute('participants').length
    const roomsNumber = this.participantsData.getAttribute('participantsDistribution').length

    const possibleHumanizedValue = this._isFlightOnly() ? this.locales.isFlightOnlyHumanized : this.locales.humanized
    const humanized = this.props.humanizedTextPattern || possibleHumanizedValue

    const textboxValue = humanized
      .replace(TEXT_PATTERNS.participants, participantsNumber)
      .replace(TEXT_PATTERNS.rooms, roomsNumber || (participantsNumber ? 1 : 0))

    if (textboxValue != null) {
      this.textBoxApi.setProp('value', textboxValue)
    }
  }

  /*
  * -----------------------------------------------------
  * TEMPLATE RELATED METHODS
  * -----------------------------------------------------
  * */

  _isFlightOnly () {
    return this.element[COMPONENT_API].isFlightOnly
  }

  _updateParticipantsFilter () {
    // Update text box value
    this._setTextBoxValue()

    // Update title and description
    if (this._isFlightOnly()) {
      this.element.querySelectorAll('.participants-is-flight-only').forEach(item => { item.style.display = 'block' })
      this.element.querySelectorAll('.participants-non-flight-only').forEach(item => { item.style.display = 'none' })
    } else {
      this.element.querySelectorAll('.participants-is-flight-only').forEach(item => { item.style.display = 'none' })
      this.element.querySelectorAll('.participants-non-flight-only').forEach(item => { item.style.display = 'block' })
    }
  }

  _getDynamicContentHTML () {
    const participantsGroupedByRoom = this.tempSelection.getParticipantsGroupedByRoom()
    let updatedParticipantsGroupedByRoom = participantsGroupedByRoom
    this.flightOnlyDistribution = null

    if (this._isFlightOnly()) {
      this.flightOnlyDistribution = participantsGroupedByRoom.flat()
      updatedParticipantsGroupedByRoom = [this.flightOnlyDistribution]
    }

    return `
    <div class="${this.props.maxRooms <= 1 ? '' : COMPONENT_QUERIES.multiRoom}">
      ${this._getAddRoomHTML()}
      ${updatedParticipantsGroupedByRoom.map((r, i) => this._getRoomGroupHTML(i)).join('')}
      ${this._getAllocationOptionsHTML()}
    </div>
    `
  }

  _getRoomGroupHTML (currentRoom = 0) {
    return (currentRoom >= this.props.maxRooms)
      ? ''
      : `<div ${COMPONENT_QUERIES.room}="${(this.roomLastIndex = currentRoom)}" class="${COMPONENT_QUERIES.roomsWrapper}">
      ${this._getRoomTitleHTML(currentRoom) + this._getParticipantSelectorsHTML(currentRoom)}</div>`
  }

  _getRoomTitleHTML (currentRoom) {
    if (this.props.maxRooms <= 1 || this._isFlightOnly()) return ''
    return `
<div class="${COMPONENT_QUERIES.roomTitleWrapperClass}">
  <h3 class="${COMPONENT_QUERIES.roomTitleClass}">${this.locales.room} ${currentRoom + 1}</h3>
  ${!currentRoom
        ? ''
        : BtnTemplate({
          variant: 'icon-only',
          icon: 'cross',
          extraClasses: COMPONENT_QUERIES.removeRoomClass
        })}
</div>`
  }

  _getParticipantSelectorsHTML (currentRoom) {
    const currentRoomParticipants = this.flightOnlyDistribution ?? this.tempSelection.getParticipantsByRoomIndex(currentRoom)

    return `
    ${this.options.ageProfiles.map(ageProfile => {
      // Do not take into account age profiles when maxPeople is set to 0 in SC
      if (!ageProfile.maxPeople || ageProfile.maxPeople.toString() === '0') return ''

      const participantsByBirth = filterDatesInRange(currentRoomParticipants, ageProfile.minBirthdate, ageProfile.maxBirthdate)
      let htmlFragment = `<div ${COMPONENT_QUERIES.profile}="${ageProfile.id}">`

      htmlFragment += NumberStepperTemplate({
        ...defaultNumberStepperData,
        id: `${this.element.id}-room-${currentRoom}-${ageProfile.id}`,
        value: participantsByBirth.length,
        minValue: (currentRoom === 0 && ageProfile.defaultProfile) ? 1 : 0,
        maxValue: ageProfile.maxPeople,
        label: ageProfile.textPlural,
        labelDescription: ageProfile.extraText != null ? `${ageProfile.extraText}` : undefined
      })

      htmlFragment += `<div class="${COMPONENT_QUERIES.childrenDates} ${ageProfile.requiresBirthdate
        ? COMPONENT_QUERIES.childrenDatesRequired
        : ''}">`
      htmlFragment += participantsByBirth.map((date, index) => this._getDateselectorsHTML({ ...ageProfile, date, index, roomId: currentRoom })).join('')
      htmlFragment += '</div></div>'

      return htmlFragment
    }).join('')}
    `
  }

  _getDateselectorsHTML (dateSelectorData) {
    return dateSelectorData.requiresBirthdate
      ? DateSelectorTemplate({
        id: `${this.element.id}-date-selector-${dateSelectorData.index}-${dateSelectorData.id}`,
        date: dateSelectorData.date || dateSelectorData.defaultBirthdate,
        minDate: dateSelectorData.minBirthdate,
        blockModifier: true,
        maxDate: dateSelectorData.maxBirthdate,
        mainLabel: `${this.locales.birthdate ? (this.locales.birthdate + ' ') : ''}
          (${dateSelectorData.textSingular} ${dateSelectorData.index + 1})`,
        attributes: {
          ...(dateSelectorData.roomId != null ? { 'data-room-id': dateSelectorData.roomId } : {})
        }
      })
      : `<input type="hidden" value="${dateSelectorData.date || dateSelectorData.defaultBirthdate}"
          ${dateSelectorData.roomId != null ? `data-room-id="${dateSelectorData.roomId}"` : ''} data-date-selector/>`
  }

  _getAddRoomHTML () {
    if (this.props.maxRooms <= 1 || this._isFlightOnly()) return ''
    return `<div class="${COMPONENT_QUERIES.addRoomClass}">${NumberStepperTemplate({
      ...defaultNumberStepperData,
      id: `${this.element.id}-add-room`,
      value: this.tempSelection.getParticipantsGroupedByRoom(),
      minValue: 1,
      maxValue: this.props.maxRooms,
      label: this.locales.addRoom,
      labelDescription: this.locales.addRoomDescription.replace(TEXT_PATTERNS.maxRooms, this.props.maxRooms)
    })}</div>`
  }

  _getAllocationOptionsHTML () {
    if (!this.options.allocationOptions || !this.options.allocationOptions.length) return ''
    const newChoiceListData = {
      id: `${this.element.id}-allocation-options`,
      items: this.options.allocationOptions.map(allocationOption => ({
        id: `${this.element.id}-allocation-options-${allocationOption.id}`,
        name: `${this.element.id}-allocation-options`,
        value: allocationOption.value,
        text: allocationOption.text,
        checked: allocationOption.value === this.tempSelection.getAttribute('allocation')
      }))
    }
    return `${ChoiceListTemplate({ ...defaultChoiceListData, ...newChoiceListData })}`
  }

  /*
  * -----------------------------------------------------
  * EVENTS RELATED METHODS
  * -----------------------------------------------------
  * */

  _buildEvents () {
    this._domEvents = [
      [this.cancel, { click: () => this._onCancel() }],
      [this.submit, { click: (ev) => this._onSubmit(ev) }]
    ]

    this._removeRoomEventObj = {
      click: (ev) => {
        const target = ev.target && ev.target.closest(`.${COMPONENT_QUERIES.removeRoomClass}`)
        if (target) {
          const roomElement = target.closest(`[${COMPONENT_QUERIES.room}]`)
          this._removeRoom(roomElement)
        }
      }
    }
    return this
  }

  _addGeneralListeners () {
    domEventsHelper.attachEvents([[this.dynamicContent, this._removeRoomEventObj]], COMPONENT_API)
  }

  _removeGeneralListeners () {
    domEventsHelper.detachEvents([[this.dynamicContent, this._removeRoomEventObj]], COMPONENT_API)
  }

  _addNumberStepperListeners (htmlFragment = this.dynamicContent) {
    Array.from(htmlFragment.querySelectorAll(`[${COMPONENT_QUERIES.profile}]`))
      .forEach((profileElement) => {
        profileElement.querySelector('[data-js-component*="c-number-stepper"]')['c-number-stepper']
          .events.on('propChanged', (changes) => {
            if (changes.name === 'value') {
              const value = changes.value
              const dateSelectors = Array.from(profileElement.querySelectorAll(COMPONENT_QUERIES.dateSelector))
              if (dateSelectors.length > value) {
                dateSelectors.filter((e, i) => i >= value).forEach((dateSelector) => {
                  dateSelector.remove()
                })
                this._removeDateSelectorListener()
                this.debouncedGetDates()
              } else {
                const profileId = profileElement.getAttribute(COMPONENT_QUERIES.profile)
                const roomId = profileElement.getAttribute(COMPONENT_QUERIES.room)
                const ageProfile = this.options.ageProfiles.find(ageProfile => ageProfile.id === profileId)
                for (let i = dateSelectors.length; i < value; i++) {
                  const dateSelectorNew = elementFromString(this._getDateselectorsHTML({ ...ageProfile, index: i, roomId }))
                  if (dateSelectorNew) {
                    Component.initDocumentComponentsFromAPI(dateSelectorNew)
                    profileElement.querySelector(`.${COMPONENT_QUERIES.childrenDates}`).appendChild(dateSelectorNew)
                    this.dateSelectors.push(dateSelectorNew)
                    this._addDateSelectorListener([dateSelectorNew], profileElement, value)
                  }
                }
              }
              this._focusParticipantsSelectorProfile(profileElement, value)
            }
          })
      })
  }

  _focusParticipantsSelectorProfile (profileElement, value) {
    this.focusedProfileElement && this.focusedProfileElement.classList.remove('c-participants-selector__profile--focus')
    this.focusedProfileElement = profileElement
    if (value > 0 && this.focusedProfileElement.getAttribute('data-c-participants-selector__profile-id') !== 'adults') {
      this.focusedProfileElement.classList.add('c-participants-selector__profile--focus')
    }
  }

  _addAddRoomStepperListener (htmlFragment = this.dynamicContent) {
    this.addRoomStepperElement = htmlFragment.querySelector(`.${COMPONENT_QUERIES.addRoomClass} > [data-js-component*="c-number-stepper"]`)
    if (!this.addRoomStepperElement) return
    this.addRoomStepperApi = this.addRoomStepperElement['c-number-stepper']

    this.addRoomStepperApi.events.on('propChanged', (changes) => {
      if (changes.name === 'value') {
        const { value, oldValue } = changes
        if (value > oldValue) {
          this._addRoom()
        } else {
          const roomElement = this.element.querySelector(`[${COMPONENT_QUERIES.room}="${this.roomLastIndex}"]`)
          this._removeRoom(roomElement)
        }
        this.focusedProfileElement && this.focusedProfileElement.classList.remove('c-participants-selector__profile--focus')
      }
    })
  }

  _addRoom () {
    const lastRoomElement = this.dynamicContent.querySelector(`[${COMPONENT_QUERIES.room}="${this.roomLastIndex}"]`)
    const newRoomElement = elementFromString(this._getRoomGroupHTML(this.roomLastIndex + 1))
    if (newRoomElement) {
      Component.initDocumentComponentsFromAPI(newRoomElement)
      lastRoomElement ? lastRoomElement.after(newRoomElement) : this.dynamicContent.appendChild(newRoomElement)
      this._addNumberStepperListeners(newRoomElement)
    }
    this._updateRoomInfo()
  }

  _removeRoom (roomElement) {
    roomElement.remove()
    this._removeNumberStepperListeners(roomElement)
    this.roomLastIndex--
    this._updateRoomInfo()
    this.debouncedGetDates()
  }

  _removeNumberStepperListeners (htmlFragment = this.dynamicContent) {
    Array.from(htmlFragment.querySelectorAll(`[${COMPONENT_QUERIES.profile}]`)).forEach((profileElement) => {
      profileElement.querySelector('[data-js-component*="c-number-stepper"]')['c-number-stepper'].events.off('propChanged')
      this.dateSelectors.filter((dateSelector) => profileElement.contains(dateSelector))
        .forEach((dateSelector) => dateSelector.remove())
    })

    this._removeDateSelectorListener()
  }

  _addDateSelectorListener (dateSelectors, profileElement, value) {
    dateSelectors.forEach((dateSelector) => {
      const dateSelectorApi = dateSelector['c-date-selector']
      if (dateSelectorApi) {
        dateSelectorApi.events.on('propChanged', (changes) => {
          if (changes.name === 'date') {
            dateSelectorApi.enableErrors()
            dateSelectorApi.valid = dateSelectorApi.validate().isValid
            this.debouncedGetDates()
          }
        })
        dateSelector.addEventListener('click', () => this._focusParticipantsSelectorProfile(profileElement, value))
      } else { this.debouncedGetDates() }
    })
  }

  _removeDateSelectorListener (htmlFragment = this.dynamicContent) {
    this.dateSelectors.filter((dateSelector) => !htmlFragment.contains(dateSelector)).forEach((dateSelector) => {
      const dateSelectorApi = dateSelector['c-date-selector']
      if (dateSelectorApi) { dateSelectorApi.events.off('propChanged') }
    })
    this.dateSelectors = this.dateSelectors.filter((dateSelector) => htmlFragment.contains(dateSelector))
  }

  _addChoiceListListener (htmlFragment = this.dynamicContent) {
    const choiceList = htmlFragment.querySelector(`#${this.element.id}-allocation-options`)
    if (choiceList) {
      choiceList['c-choice-list'].events.on('changeOptions', (options) => {
        this.tempSelection.setAttribute('allocation', options.filter((option) => option.checked).map(({ value }) => value).join(','))
      })
    }
  }

  _removeChoiceListListener (htmlFragment = this.dynamicContent) {
    const choiceList = htmlFragment.querySelector(`#${this.element.id}-allocation-options`)
    if (choiceList) { choiceList['c-choice-list'].events.off('changeOptions') }
  }

  /**
   * Handled by focus on input
   *
   * - Creates tempSelection
   * - Opens dialog
   * - Emit FOCUS
   */
  _onFocus (ev) {
    if (!this.tempSelection) {
      this.tempSelection = new ParticipantsDataModel(this.participantsData.attributes)
      this._updateDynamicContent()
      this._openFloatingBox()
      this.events.emit('focus')
    }
  }

  _onCancel (ev) {
    const delegateTarget = ev && ev.target && ev.target.closest(`.${COMPONENT_API}`)
    if (!delegateTarget || this.element !== delegateTarget) { this._closeFloatingBox() }
  }

  _onSubmit () {
    const { participants, participantsDistribution, allocation } = this.tempSelection.attributes

    const shouldFire = this._participantsDataChanged(participants, participantsDistribution, allocation)

    if (shouldFire) {
      this.setProps({ participants, participantsDistribution, allocation: allocation || '' })
        .then(() => {
          this.events.emit('submit', this.props)
        })
    }

    this._closeFloatingBox()
  }

  _participantsDataChanged (participants, participantsDistribution, allocation) {
    const oldDistribution = this.participantsData.getAttribute('participantsDistribution')
    const oldParticipants = this.participantsData.getAttribute('participants')
    const oldAllocation = this.participantsData.getAttribute('allocation')

    const distributionChanged = JSON.stringify(oldDistribution) !== JSON.stringify(participantsDistribution)
    const participantsChanged = JSON.stringify(oldParticipants) !== JSON.stringify(participants)
    const allocationChanged = oldAllocation !== allocation

    return distributionChanged || participantsChanged || allocationChanged
  }

  _onDocumentClick (ev) {
    const closestResult = (el, lastParentToCheck) => {
      do {
        if (el === lastParentToCheck) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el !== lastParentToCheck.parentElement && el.nodeType === 1)
      return null
    }
    const delegateTarget = closestResult(ev.target, this.element)
    if (!delegateTarget) {
      queueFilterEvent(ev, this.element, this._onSubmit.bind(this), true)
    }
  }

  // Method to change the ageProfiles from outside the component, and not just take what's on the html.
  // Right now this is only used on the QuickSearch, as there is the only place where a ParticipantSelector
  // can change the used age profiles depending on the selected publication
  setAgeProfiles (ageProfiles) {
    this.options.ageProfiles = ageProfiles.map(ap => this._mapAgeProfile(ap))
  }

  _mapAgeProfile (ageProfile) {
    return {
      ...ageProfile,
      textSingular: ageProfile.text.singular,
      textPlural: ageProfile.text.plural,
      defaultProfile: ageProfile.isDefaultProfile,
      defaultBirthdate: ageProfile.defaultBirthdate.split('T')[0],
      minBirthdate: ageProfile.minBirthdate.split('T')[0],
      maxBirthdate: ageProfile.maxBirthdate.split('T')[0],
      id: CORRECT_AGE_PROFILE_NAMES[ageProfile.id]
    }
  }
}

registerComponent(ParticipantsSelector, COMPONENT_DEFINITION.name, COMPONENT_DEFINITION)
