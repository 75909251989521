import { ImgTemplate } from '../img/c-img.template'
import { BtnTemplate } from '../btn/c-btn.template'

/**
 * The ImgData contains all the data needed to hydrate a Img component
 *
 * @typedef {Object}      ImgData
 *
 * @property {Boolean}              sync             - It determines if the image is loaded in sync mode
 * @property {String}               ratio            - The ratio of the image
 * @property {String}               extraClasses     - The extra classes
 * @property {String}               resolvedSrc      - The src of the resolved image
 * @property {String}               resolve          - The image resolve type none|default|load|intersect
 * @property {Object}               attributes       - The attributes
 * @property {String}               placeholderSrc   - The src of the placeholder
 * @property {String}               title            - The image title
 * @property {DynamicRatioData[]}   [dynamicRatios]  - The dynamic ratios for the image
 * @property {Boolean}              isZoomable       - It determines if the image is zoomable or not
 * @property {Boolean}              keepOriginalSize - It determines if the image has its original size or it's resized with the parent element.
 */
export const defaultImgBoxBodyData = {
  sync: false,
  ratio: '',
  extraClasses: '',
  resolvedSrc: '',
  resolve: '',
  attributes: {},
  placeholderSrc: '',
  title: '',
  dynamicRatios: [],
  isZoomable: false,
  keepOriginalSize: true
}

export const zoomOutButton = {
  attributes: { 'data-img-box__buttons--zoom--out': '' },
  type: 'button',
  block: false,
  disabled: false,
  icon: 'minus',
  iconPosition: 'left',
  jsApi: false,
  loading: false,
  variant: 'icon-only',
  text: '',
  link: {},
  extraClasses: 'c-img-box__buttons--zoom c-img-box__buttons--zoom--out'
}

export const zoomInButton = {
  attributes: { 'data-img-box__buttons--zoom--in': '' },
  type: 'button',
  block: false,
  disabled: false,
  icon: 'plus',
  iconPosition: 'left',
  jsApi: false,
  loading: false,
  variant: 'icon-only',
  text: '',
  link: {},
  extraClasses: 'c-img-box__buttons--zoom c-img-box__buttons--zoom--in'
}

export const cancelButton = {
  attributes: { 'data-img-box__close-button': '' },
  type: 'button',
  block: false,
  disabled: false,
  icon: 'cross',
  iconPosition: 'left',
  jsApi: false,
  loading: false,
  variant: 'icon-only',
  text: '',
  link: {},
  extraClasses: 'c-img-box__buttons--close'
}

export const ImgBoxBodyTemplate = (d) => {
  d = { ...defaultImgBoxBodyData, ...d }

  return `
<div class="c-img-box-body">
    <div class="c-img-box-close-button">
      ${BtnTemplate(cancelButton)}
    </div>
    ${ImgTemplate(d)}
    ${d.isZoomable
    ? `<div class="c-img-box__buttons">
      ${BtnTemplate(zoomOutButton)}
      ${BtnTemplate(zoomInButton)}
    </div>`
    : ''
    }
</div>
`
}
