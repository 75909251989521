export const defaultVideoData = {
  /**
   * The VideoData contains all the data needed to hydrate a Video component
   *
   * @typedef {Object}      VideoData
   *
   * @property {Boolean}              isDescriptionAfterVideo             - It determines if the image is loaded in sync mode
   * @property {String}               title                               - The ratio of the image
   * @property {String}               description                         - The extra classes
   * @property {String}               apiKey                              - The src of the resolved image
   * @property {String}               url                                 - The image resolve type none|default|load|intersect
   * @property {Object}               thumbnail                           - The attributes
   */

  title: '',
  isDescriptionAfterVideo: true,
  description: '',
  apiKey: '',
  url: '',
  thumbnail: ''
}

export const VideoTemplate = (d) => {
  d = { ...defaultVideoData, ...d }

  return `
  <div class="c-video" data-js-component="c-video">

    ${d.title
      ? `<div class="c-video-title m-heading m-heading--medium">
          <span>${d.title}</span>
        </div>`
      : ''}

    ${d.isDescriptionAfterVideo
      ? ''
      : `${d.description
        ? `<div class="c-video-subtitle c-video-subtitle--isup m-body m-body--medium">
            <span>${d.this.description}</span>
          </div>`
        : ''}
    `}

    ${d.apiKey
      ? `<div class="c-video-wrapper o-ratio o-ratio--16:9">
        <div id="${d.id}" class="c-video-youtube o-ratio__content" width="560" height="315" data-video-src="${d.url}" allowfullscreen allow="autoplay" data-api-key="${d.apiKey}" videoid=""></div>
        <div class="c-video-thumbnail o-ratio__content">

           ${d.thumbnail
            ? `<img src="${d.thumbnail}" class="c-video-thumbnail-image o-ratio__content "></img>`
            : '<img src="" class="c-video-thumbnail-image o-ratio__content no-original-thumbnail"></img>'}

          <div class="c-video-thumbnail-info">
            <div class="c-video-thumbnail-info-icon-wrapper">
              <i class="c-video-thumbnail-info-icon m-icon m-icon--play"></i>
              <div class="c-video-thumbnail-info-duration "></div>
            </div>
            <div class="c-video-thumbnail-info-name m-heading m-heading--small"></div>
          </div>
        </div>
      </div>`
    : ''}

    ${d.isDescriptionAfterVideo
      ? `${d.description
        ? `<div class="c-video-subtitle c-video-subtitle--isup m-body m-body--medium">
            <span>${d.this.description}</span>
          </div>`
        : ''}`
      : ''}
  </div>
  `
}
