/**
 * The TextareaMessagesTemplate contains all the data needed to hydrate a Messages component
 *
 * @typedef {Object}      defaultTextareaMessagesData
 *
 * @property {String}     extraClasses      - Extra classes if needed
 */

export const defaultTextareaMessagesData = {
  extraClasses: ''
}

export const TextareaMessagesTemplate = (d) => `
  ${d
    ? `
        ${d.map(message => `
          <div class="c-textarea__message">
            <i class="m-icon m-icon--warning-circle"></i>
            <span>${message}</span>
          </div>
    `).join('')}
      `
    : ''}
`
