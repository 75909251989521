import * as cssHelper from '../../../js/document/css'
import Backdrop from '../backdrop/main'
import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {

  name: 'c-side-drawer',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'open',
      attr: '.in',
      type: 'boolean',
      defaultValue: false
    }
  ],
  actionElements: true
}

/**
 * Side Drawer content
 *
 */
export default class SideDrawer extends Component {
  /**
   * Creates a new Side Drawer behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.settings = {
      classNames: {
        opened: 'in',
        closing: 'is-closing'
      },
      utilitiesClassNames: {
        hiddenFrom: 'u-hidden--from@BP'
      },
      attributes: {
        origin: 'data-c-side-drawer__origin',
        until: 'data-c-side-drawer__until',
        action: 'data-c-side-drawer__action',
        id: 'data-c-side-drawer__id',
        backdropExtraClasses: 'data-c-side-drawer__backdrop-extra-classes'
      }
    }

    this.backdrop = null
    this.until = this.element.getAttribute(this.settings.attributes.until)
    this.backdropExtraClasses = this.element.getAttribute(this.settings.attributes.backdropExtraClasses)
    this._transitionTime = cssHelper.cssTimeToMs(cssHelper.getStyle(this.element, 'transition-duration'))

    element[this.name].remove = this.remove.bind(this)
    element[this.name].open = this.open.bind(this)
    element[this.name].close = this.close.bind(this)
    element[this.name].toggle = this.toggle.bind(this)
  }

  setOpen (value) { return value ? this._open() : this._close() }

  open () { return this.setProp('open', true) }

  close () { return this.setProp('open', false) }

  async _open () {
    return new Promise((resolve) => {
      this.events.emit('open')
      this._openBackdrop()
      this.element.classList.add(this.settings.classNames.opened)

      setTimeout(() => {
        this.events.emit('opened')
        resolve()
      }, this._transitionTime)
    })
  }

  _openBackdrop () {
    this.backdrop = Backdrop()
    if (this.until) {
      this.backdrop.element.classList.add(this.settings.utilitiesClassNames.hiddenFrom.replace('BP', this.until))
    }
    if (this.backdropExtraClasses) {
      this.backdrop.element.classList.add(this.backdropExtraClasses)
    }
    this.backdrop.open()
    this.backdrop.element.addEventListener('click', () => this.close())
  }

  async _close () {
    return new Promise((resolve) => {
      this.events.emit('close')

      this.element.classList.remove(this.settings.classNames.opened)
      this.element.classList.add(this.settings.classNames.closing)
      this._closeBackdrop()

      setTimeout(() => {
        this.element.classList.remove(this.settings.classNames.closing)
        this.events.emit('closed')
        resolve()
      }, this._transitionTime)
    })
  }

  _closeBackdrop () {
    this.backdrop.element.removeEventListener('click', () => this.close())
    this.backdrop.close(this.backdrop.destroy)
  }

  toggle () {
    return this.props.open ? this.close() : this.open()
  }

  remove () {
    this.close().then(() => {
      delete this.element[this.name]
    })
  }
}

registerComponent(SideDrawer, definition.name, definition)
