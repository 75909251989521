export const da = {
  addRoom: 'Tilføj ekstra værelse',
  addRoomDescription: 'Max. {MAX_ROOMS} værelser.',
  birthdate: 'Fødselsdato',
  humanized: '{NUM_ROOMS} værelse(r), {PARTICIPANTS} deltagere',
  isFlightOnlyHumanized: '{PARTICIPANTS} deltagere',
  placeholder: 'Hvem rejser ...',
  title: 'Organiser dine rejsende',
  room: 'Værelse'
}

export const de = {
  addRoom: 'In extra Zimmer',
  addRoomDescription: 'Max. {MAX_ROOMS} Zimmer.',
  birthdate: 'Geburtstag',
  humanized: '{NUM_ROOMS} zimmer {PARTICIPANTS} treilnehmer',
  isFlightOnlyHumanized: '{PARTICIPANTS} treilnehmer',
  placeholder: 'Wer reist ...',
  title: 'Organisieren Sie Ihre Reisenden',
  room: 'Zimmer'
}

export const en = {
  addRoom: 'Add extra room',
  addRoomDescription: 'Max. {MAX_ROOMS} rooms.',
  birthdate: 'Birthdate',
  humanized: '{NUM_ROOMS} room(s), {PARTICIPANTS} participants',
  isFlightOnlyHumanized: '{PARTICIPANTS} participants',
  placeholder: 'Who travels ...',
  title: 'Organize your travellers',
  room: 'Room'
}

export const nb = {
  addRoom: 'Legg til ekstra rom',
  addRoomDescription: 'Maks {MAX_ROOMS} rom.',
  birthdate: 'Bursdag',
  humanized: '{NUM_ROOMS} rom, {PARTICIPANTS} deltakere',
  isFlightOnlyHumanized: '{PARTICIPANTS} deltakere',
  placeholder: 'Hvem reiser ...',
  title: 'Organiser reisende',
  room: 'Rom'
}
export const fr = {
  addRoom: 'Ajouter chambre supplémentaire',
  addRoomDescription: 'Max. {MAX_ROOMS} chambres.',
  birthdate: 'Date de naissance',
  humanized: '{NUM_ROOMS} chambre(s), {PARTICIPANTS} participants',
  isFlightOnlyHumanized: '{PARTICIPANTS} participants',
  placeholder: 'Qui voyage ...',
  title: 'Organisez vos voyageurs',
  room: 'Chambre'
}

export const nl = {
  addRoom: 'Voeg extra kamer',
  addRoomDescription: 'Max. {MAX_ROOMS} kamers.',
  birthdate: 'Geboortedatum',
  humanized: '{NUM_ROOMS} kamer(s), {PARTICIPANTS} deelnemers',
  isFlightOnlyHumanized: '{PARTICIPANTS} deelnemers',
  placeholder: 'Wie reist er ...',
  title: 'Organiseer uw reizigers',
  room: 'Kamer'
}

export const sv = {
  addRoom: 'Lägg extra rum',
  addRoomDescription: 'Max {MAX_ROOMS} rum.',
  birthdate: 'Födelsedatum',
  humanized: '{NUM_ROOMS} rum, {PARTICIPANTS} deltagare',
  isFlightOnlyHumanized: '{PARTICIPANTS} deltagare',
  placeholder: 'Vem reser ...',
  title: 'Organisera dina resenärer',
  room: 'Rum'
}
