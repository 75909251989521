import { encodeAttributes } from '../../../js/helpers/string'

export const defaultImgData = {
  /**
   * The ImgData contains all the data needed to hydrate a Img component
   *
   * @typedef {Object}      ImgData
   *
   * @property {Boolean}              sync             - It determines if the image is loaded in sync mode
   * @property {String}               ratio            - The ratio of the image
   * @property {String}               extraClasses     - The extra classes
   * @property {String}               resolvedSrc      - The src of the resolved image
   * @property {String}               resolve          - The image resolve type none|default|load|intersect
   * @property {Object}               attributes       - The attributes
   * @property {String}               placeholderSrc   - The src of the placeholder
   * @property {String}               title            - The image title
   * @property {DynamicRatioData[]}   [dynamicRatios]  - The dynamic ratios for the image
   * @property {Boolean}              keepOriginalSize - It determines if the image has its original size or it's resized with the parent element.
   * @property {String}               track            - Event name to be tracked by DataLayers
   */
  sync: false,
  ratio: '',
  extraClasses: '',
  resolvedSrc: '',
  resolve: '',
  attributes: {},
  placeholderSrc: '',
  title: '',
  dynamicRatios: [],
  keepOriginalSize: false,
  track: ''
}

/**
 * The DynamicRatioData contains all the data needed to hydrate a DynamicRatioData component
 *
 * @typedef {Object}      DynamicRatioData
 *
 * @property {String}     ratio          - The ratio of the image
 * @property {String}     bp             - The breakpoint where ratio should apply from
 */
export const defaultDynamicRatioData = {
  ratio: '',
  bp: ''
}

export const ImgTemplate = (d) => {
  d = { ...defaultImgData, ...d }

  return `
<div class="c-img
    ${d.sync ? 'c-img--sync' : ''}  
    ${d.ratio ? `o-ratio o-ratio--${d.ratio}` : ''}
    ${d.dynamicRatios.map(item => `o-ratio--${item.ratio}@${item.bp}`).join(' ')}
    ${d.extraClasses || ''}"  
    ${d.track ? `data-track="${d.track}"` : ''}
    data-img-src="${d.resolvedSrc}"
    ${d.sync
      ? (d.track ? 'data-js-component="c-img"' : '')
      : `data-js-component="c-img" ${d.resolve ? `data-c-img__resolve=${d.resolve}` : ''}`}
    ${d.keepOriginalSize ? 'data-c-img__keep-original-size' : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  
    ${d.sync
      ? `<img src="${d.resolvedSrc}" class="c-img__resolved ${(d.ratio || (d.dynamicRatios && d.dynamicRatios.length)) ? 'o-ratio__content' : ''}" ${d.title ? `alt="${d.title}"` : ''}>`
      : `<img src="${d.placeholderSrc}" class="c-img__placeholder ${(d.ratio || (d.dynamicRatios && d.dynamicRatios.length)) ? 'o-ratio__content' : ''}" ${d.title ? `alt="${d.title}"` : ''}>`}
</div>
`
}
