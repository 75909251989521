import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The NumberStepperData contains all data needed to hydrate a NumberStepper view
 *
 * @typedef {Object}          NumberStepperData
 *
 * @property {String}         id                - The element id
 * @property {Integer}        value             - The current value
 * @property {Integer}       [minValue]         - The minimum value
 * @property {Integer}       [maxValue]         - The maximum value
 * @property {Integer}       [step]             - The default step for increment/decrement
 * @property {String}        [label]            - The label text
 * @property {String}        [labelDescription] - The label description text
 * @property {Boolean}       [hiddenLabel]      - To make the label hidden but readable
 * @property {String}        [extraClasses]     - Extra classes to append to the view
 * @property {Object}        [attributes]       - Extra attributes
 */

export const defaultNumberStepperData = {
  id: '',
  value: 0,
  minValue: 0,
  step: 1,
  attributes: {}
}

export const NumberStepperTemplate = (d) => `
<div
  id="${d.id}"
  class="c-number-stepper
    ${d.extraClasses ? d.extraClasses : ''}"
  data-js-component="c-number-stepper"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.label
    ? `<label
    class="c-number-stepper__label e-form-label"
    for="${d.id}__input"
    ${d.hiddenLabel ? `aria-hidden="true" aria-label="${d.label}"` : ''}>
    ${d.label}
    ${d.labelDescription ? `<span class="e-form-label__description">${d.labelDescription}</span>` : ''}
    </label>`
    : ''}
    <div class="c-number-stepper__body">
      <input
        id="${d.id}__input"
        class="c-number-stepper__input"
        type="number"
        readonly
        ${d.value || d.value === 0 ? `value="${d.value}"` : ''}
        ${d.minValue || d.minValue === 0 ? `min="${d.minValue}"` : ''}
        ${d.maxValue || d.maxValue === 0 ? `max="${d.maxValue}"` : ''}
        ${d.step ? `step="${d.step}"` : ''}>
      <button type="button" class="c-btn c-btn--icon-only" data-js-component="c-btn" data-c-number-stepper__decrement=""><i class="c-btn__icon c-btn__icon--left m-icon--minus"></i></button>
      <button type="button" class="c-btn c-btn--icon-only" data-js-component="c-btn" data-c-number-stepper__increment=""><i class="c-btn__icon c-btn__icon--left m-icon--plus"></i></button>
    </div>
</div>`
