import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { TextboxMessagesTemplate } from './c-textbox__messages.template'

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {

  name: 'c-textbox',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'value',
      type: 'string'
    }, {
      name: 'state',
      type: 'string',
      attr: '.has-',
      allowedValues: [
        '',
        'success',
        'warning',
        'error'
      ],
      defaultValue: ''
    }, {
      name: 'disabled',
      type: 'boolean',
      attr: '.is-disabled',
      defaultValue: false
    },
    {
      name: 'required',
      type: 'boolean',
      attr: '.is-required',
      defaultValue: false
    }
  ]

}

/** @type {Object} Class names used */
const classNames = {
  hidden: 'is-hidden',
  iconHide: 'm-icon--eye-off',
  iconEye: 'm-icon--eye',
  visibilityPasswordIcon: `.${definition.name}__icon--password`,
  passwordRequirementChecked: `${definition.name}__password-requirement--checked`
}

/** @type {Object} attributes used */
const attributes = {
  passwordRequirements: `data-${definition.name}__password-requirement-pattern`
}

/**
 * Textbox content
 *
 */
export default class Textbox extends Component {
  /**
   * Creates a new textbox behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.silentErrors = true

    const textBoxNode = this._getTextboxNode()

    textBoxNode.addEventListener('change', (e) => {
      const newValue = e.target.value.trim()
      if (this.props.value !== newValue) this.setProp('value', newValue)
    })
    textBoxNode.addEventListener('focus', (e) => this.events.emit('focus', e))
    textBoxNode.addEventListener('blur', (e) => this.events.emit('blur', e))
    textBoxNode.addEventListener('keydown', (e) => this.events.emit('keydown', e))
    textBoxNode.addEventListener('keyup', (e) => this.events.emit('keyup', e))
    textBoxNode.addEventListener('input', (e) => this.events.emit('input', e))

    const clearNode = this._getClearNode()
    if (clearNode) {
      clearNode.addEventListener('click', (e) => {
        const newValue = ''
        if (this.props.value !== newValue) this.setProp('value', newValue)
        this.events.emit('clear')
      })
    }

    this.messagesElement = this.element.querySelector('.c-textbox__messages')
    this.messagePattern = this._getTextboxNode().getAttribute('data-message-pattern')
    this.messageRequired = this._getTextboxNode().getAttribute('data-message-required')
    this.messageRangeUnderflow = this._getTextboxNode().getAttribute('data-message-value-range-underflow')
    this.messageTypeMismatch = this._getTextboxNode().getAttribute('data-message-typeMismatch')
    this.messageValueConfirmation = this._getTextboxNode().getAttribute('data-message-value-confirmation')
    this.valueConfirmationElementId = this._getTextboxNode().getAttribute('data-id-element-value-confirmation')
    if (this.valueConfirmationElementId) {
      this.valueConfirmationElement = document.getElementById(this.valueConfirmationElementId)
    }

    this.element[definition.name].validate = this.validate.bind(this)
    this.element[definition.name].enableErrors = this.enableErrors.bind(this)
    this.element[definition.name].setValidityResult = this._styleValidity.bind(this)

    if (this._isPasswordType()) {
      this._addPasswordInputLogic()
      this.passwordRequirements = this.element.querySelectorAll(`[${attributes.passwordRequirements}]`)
      if (this.passwordRequirements.length) {
        this.events.on('input', () => this._validatePasswordRequirements())
      }
    }
  }

  getValue () {
    return this._getTextboxNode().value
  }

  async setValue (value) {
    this._getTextboxNode().value = value
    this.element.classList[value ? 'add' : 'remove']('has-value')
  }

  _getTextboxNode () {
    this.textboxNode = this.textboxNode || this.element.querySelector('.c-textbox__element')
    return this.textboxNode
  }

  _getClearNode () {
    this.clearNode = this.clearNode || this.element.querySelector('.c-textbox__clear')
    return this.clearNode
  }

  enableErrors () {
    this.silentErrors = false
  }

  validate (validateOnly = false) {
    this._doCustomValidations()
    const isValid = this._getTextboxNode().validity.valid
    let messages = []
    let messageTypes = []
    if (!isValid) {
      messages = this._getValidationMessages()
      messageTypes = this._getValidationMessageTypes()
    }
    if (!validateOnly && !this.silentErrors) {
      this._styleValidity(isValid, messages)
    }
    return {
      isValid,
      errorMessages: messages,
      errorTypes: messageTypes,
      fieldName: this._getTextboxNode().name,
      fieldValue: this.getValue(),
      fieldElement: this._getTextboxNode()
    }
  }

  _doCustomValidations () {
    this._validateValueConfirmation()
  }

  _validateValueConfirmation () {
    if (this.valueConfirmationElement) {
      if (this._getTextboxNode().value !== this.valueConfirmationElement.value) {
        this._getTextboxNode().setCustomValidity(this.messageValueConfirmation)
        this._getTextboxNode().validity.valueNotConfirmed = true
      } else {
        this._getTextboxNode().setCustomValidity('')
        this._getTextboxNode().validity.valueNotConfirmed = false
      }
    }
  }

  _getValidationMessages () {
    const messages = []
    const validity = this._getTextboxNode().validity
    if (validity.patternMismatch && this.messagePattern) {
      messages.push(this.messagePattern)
    }
    if (validity.typeMismatch && this.messageTypeMismatch) {
      messages.push(this.messageTypeMismatch)
    }
    if (validity.valueNotConfirmed && this.messageValueConfirmation) {
      messages.push(this.messageValueConfirmation)
    }
    if (validity.valueMissing && this.messageRequired) {
      messages.push(this.messageRequired)
    }
    if (validity.rangeUnderflow && this.messageRangeUnderflow) {
      messages.push(this.messageRangeUnderflow)
    }
    return messages
  }

  _getValidationMessageTypes () {
    const messageTypes = []
    const validity = this._getTextboxNode().validity
    if (validity.patternMismatch && this.messagePattern) {
      messageTypes.push('PatternMismatch')
    }
    if (validity.typeMismatch && this.messageTypeMismatch) {
      messageTypes.push('TypeMismatch')
    }
    if (validity.valueNotConfirmed && this.messageValueConfirmation) {
      messageTypes.push('ValueNotConfirmed')
    }
    if (validity.valueMissing && this.messageRequired) {
      messageTypes.push('ValueMissing')
    }
    if (validity.rangeUnderflow && this.messageRangeUnderflow) {
      messageTypes.push('rangeUnderflow')
    }
    return messageTypes
  }

  _styleValidity (isValid, messages) {
    if (!isValid) {
      this.element.classList.add('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = TextboxMessagesTemplate(messages)
      }
    } else {
      this.element.classList.remove('has-error')
      if (this.messagesElement !== null && this.messagesElement !== undefined) {
        this.messagesElement.innerHTML = ''
      }
    }
  }

  _addPasswordInputLogic () {
    const passwordIconElement = this.element.querySelector(classNames.visibilityPasswordIcon)
    const passwordInputNode = this._getTextboxNode()
    if (passwordIconElement && passwordInputNode) {
      passwordIconElement.addEventListener('click', this._passwordIconHandler.bind(this))
      this.events.on('input', () => this._changeVisibilityPasswordIconHandler(passwordInputNode, passwordIconElement))
      this._changeVisibilityPasswordIconHandler(passwordInputNode, passwordIconElement)
    }
  }

  _isPasswordType () {
    return this._getTextboxNode().type === 'password'
  }

  _passwordIconHandler (event) {
    if (this._isPasswordType()) {
      this._changeVisibilityPassword(event.currentTarget, classNames.iconHide, classNames.iconEye, 'text')
    } else {
      this._changeVisibilityPassword(event.currentTarget, classNames.iconEye, classNames.iconHide, 'password')
    }
  }

  _changeVisibilityPassword (target, classIconToAdd, classIconToRemove, inputType) {
    target.classList.add(classIconToAdd)
    target.classList.remove(classIconToRemove)
    this._getTextboxNode().setAttribute('type', inputType)
  }

  _changeVisibilityPasswordIconHandler (passwordInput, passwordIconElement) {
    passwordIconElement.classList[passwordInput.value ? 'remove' : 'add'](classNames.hidden)
  }

  /** Validate the password requirements */
  _validatePasswordRequirements () {
    const passwordValue = this.getValue()
    this.passwordRequirements.forEach(passwordRequirement => {
      const passwordRequirementPattern = passwordRequirement.getAttribute(attributes.passwordRequirements)
      if (passwordValue.match(passwordRequirementPattern)) {
        passwordRequirement.classList.add(classNames.passwordRequirementChecked)
      } else {
        passwordRequirement.classList.remove(classNames.passwordRequirementChecked)
      }
    })
  }

  setDisabled (isDisabled) {
    this.element.classList.toggle('is-disabled', isDisabled)
    this._getTextboxNode().toggleAttribute('disabled', isDisabled)
  }

  setRequired (isRequired) {
    this.element.classList.toggle('is-required', isRequired)
    this._getTextboxNode().toggleAttribute('required', isRequired)
  }
}

registerComponent(Textbox, definition.name, definition)
