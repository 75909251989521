import Model from '../../../js/core/model'
import storage from '../../../js/document/web-storage'

export const COOKIE_NAME = 'participants'
export const COOKIE_SETTINGS = {
  expirationDays: 30,
  path: '/'
}

/**
 * A participantsData model
 */
export default class ParticipantsDataModel extends Model {
  /**
   * Creates a new ParticipantsDataModel
   * @constructor
   * @param {Object} attributes - The model attributes
   * @param {DateString[]} attributes.participants - Participants birthdates
   * @param {String|null} attributes.allocation - Chosen allocation option
   * @param {String[]} attributes.participantsDistribution - How participants are organized into rooms,
   * as array of pipe separated indexes (one based): ['1|2','3|4']
   * @param {Object} [options] - The model options
   * @param {Boolean} [options.cookieEnabled] - If true, data will be synchronized with cookies
   * @param {Boolean} [options.saveCookie] - If true, cookie will be saved
   * @param {String} [options.cookieName] - Name of the cookie to synchronize
   * @param {Object} [options.cookieSettings] - Settings of the cookie to synchronize
   */

  constructor (attributes = {}, options = {}) {
    super({
      ...{
        participants: [],
        allocation: '',
        participantsDistribution: []
      },
      ...attributes
    })

    this.options = {
      ...{
        cookieEnabled: false,
        saveCookie: false,
        cookieName: COOKIE_NAME,
        cookieSettings: COOKIE_SETTINGS
      },
      ...options
    }

    if (this.isCookieEnabled()) {
      this.setAttributesFromCookie({ silent: true })
      this.events.on('change', this.setCookieFromAttributes.bind(this))
    }
  }

  /**
   * Sets a participant's date by index. If index does not exist, it will add a new participant's date
   * @param {Array[]} distributedDates - Dates already distributed (Ej: [[date1, date2], [date3, date4]]
   * @param {ModelActionOptions} [options] - ModelActionOptions object
   *
   * @returns {ParticipantsDataModel} Self instance.
   */
  setParticipantAndDistribution (distributedDates, options) {
    const participants = distributedDates.reduce((a, b) => [...a, ...b], [])
    let index = 1
    const participantsDistribution = distributedDates.reduce((array, dates) => [...array, dates.map(() => index++).join('|')], [])
    this.setAttributes({ participants, participantsDistribution }, options)
    return this
  }

  /**
   * Returns a multidimensional array with the participants (BirthDates) organized into rooms allocation,
   * eg. [ ['1979-01-01','1985-03-03'], ['2013-01-01','2015-03-03'] ]
   *
   * @returns {[DateString[]]} Matching participants for that room
   */
  getParticipantsGroupedByRoom () {
    if (!this.attributes.participantsDistribution.length) return [this.attributes.participants]
    return this.attributes.participantsDistribution.map((v, i) => this.getParticipantsByRoomIndex(i))
  }

  /**
   * Returns the participants (BirthDates) allocated in given room index (zero based)
   *
   * @param {Integer} roomIndex - The room index (zero based)
   *
   * @returns {DateString[]} Matching participants for that room
   */
  getParticipantsByRoomIndex (roomIndex = 0) {
    if (roomIndex === 0 && !this.attributes.participantsDistribution.length) return this.attributes.participants
    if (this.attributes.participantsDistribution[roomIndex] === undefined) return []
    const matchingParticipantsIndexes = this.attributes.participantsDistribution[roomIndex].split('|').map(n => parseInt(n - 1))
    return matchingParticipantsIndexes.map(i => this.attributes.participants[i])
  }

  /**
   * Returns true if the cookies are enabled
   *
   * @returns {Boolean} Cookie enabled
   */
  isCookieEnabled () {
    return !!this.options.cookieEnabled
  }

  /**
   * Set attributes from cookie data, if there's any and valid
   *
   * @param {ModelActionOptions} [options] - ModelActionOptions object
   *
   * @returns {ParticipantsDataModel} Self instance
   */
  setAttributesFromCookie (options = {}) {
    try {
      const cookieValue = storage.cookie.get(this.options.cookieName)
      if (this._checkCookieIntegrity(cookieValue)) {
        const newAttributes = ParticipantsDataModel.getParticipantsAttributesFromCookie(cookieValue)
        this.setAttributes(
          newAttributes,
          options
        )
      } else {
        storage.cookie.delete(this.options.cookieName)
      }
    } catch (e) {}
    return this
  }

  /**
   * Set cookie from attributes data
   *
   * @returns {ParticipantsDataModel} Self instance
   */
  setCookieFromAttributes () {
    if (this.options.saveCookie) {
      const cookieValue = ParticipantsDataModel.getParticipantsCookieFromAttributes(this.attributes)
      if (this._checkCookieIntegrity(cookieValue)) {
        storage.cookie.set(
          this.options.cookieName,
          cookieValue,
          this.options.cookieSettings
        )
      } else {
        storage.cookie.delete(this.options.cookieName)
      }
    }
    return this
  }

  /**
   * Get participants props from cookie
   *
   * @param {Object} cookieValue - Participants cookie
   *
   * @returns {Object} ParticipantsDataModel attributes
   */
  static getParticipantsAttributesFromCookie (cookieValue) {
    const { rooms, allocation } = cookieValue
    const participants = rooms.reduce((a, b) => [...a, ...b], [])
    let index = 1
    const participantsDistribution = rooms.reduce((array, dates) => [...array, dates.map(() => index++).join('|')], [])
    return {
      allocation: (allocation === null || allocation === undefined) ? undefined : String(allocation),
      participants,
      participantsDistribution
    }
  }

  /**
   * Get participants cookie from props
   *
   * @param {Object} attributes - ParticipantsDataModel attributes
   *
   * @returns {Object} Participants cookie
   */
  static getParticipantsCookieFromAttributes (attributes) {
    const rooms = attributes.participantsDistribution.map(room => {
      return room.split('|')
        .map(i => parseInt(i))
        .map(i => attributes.participants[i - 1])
    })
    const allocation = attributes.allocation || undefined
    return {
      allocation,
      rooms
    }
  }

  /**
   * Check if there is any null value on rooms attribute
   *
   * @param {Object} cookieValue - CookieValue
   *
   * @returns {Boolean} true/false dependig if there is a null value or not
   */
  _checkCookieIntegrity (cookieValue) {
    return !cookieValue.rooms.some(room => room.some(value => value === null))
  }
}
