import { getTokenClass } from '../../../js/helpers/tokens'
import { encodeAttributes } from '../../../js/helpers/string'
import { ModalHeaderTemplate } from './c-modal__header.template'
import { ModalBodyTemplate } from './c-modal__body.template'
import { ModalFooterTemplate } from './c-modal__footer.template'

// Import tokens
const tokensShared = require('./data/c-modal__tokens.json')['c-modal']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/modal/data/c-modal__tokens.json`)['c-modal'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultModalData = {
  size: 'medium',
  id: '',
  async: false,
  extraClasses: '',
  closable: true,
  openOnLoad: false,
  hiddenBackdrop: false,
  attributes: null,
  style: 'default',
  headerTag: 'h2',
  title: '',
  bodyContent: '',
  footerContent: '',
  headerSize: 'default'
}

export const ModalTemplate = (d) => {
  d = { ...defaultModalData, ...d }
  return `
<div ${d.id ? `id="${d.id}"` : ''}
  class="c-modal ${getTokenClass('size', d.size, tokens)} ${d.async ? 'c-modal--async' : ''} ${d.extraClasses}"
  data-js-component="c-modal" 
  ${d.closable ? 'data-c-modal--closable' : ''}
  ${d.openOnLoad ? 'data-c-modal--open-on-load' : ''}
  ${d.hiddenBackdrop ? 'data-c-modal--hidden-backdrop' : ''}
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
   ${ModalHeaderTemplate(d)}
   ${ModalBodyTemplate(d)}
   ${ModalFooterTemplate(d)}
 </div>
`
}
